import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import ProfileContext from '../../context/profile/profileContext';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const profileContext = useContext(ProfileContext);
  const { token } = profileContext;

  return (
    <Route
      {...rest}
      render={props =>
        !token ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;

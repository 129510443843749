import React, { useContext, useEffect, useState } from "react";
import Alert from "../component/Alert";
import Button from "../component/Button";
import Spinner from "../component/Spinner";
import { Link } from "react-router-dom";
import ProductionContext from "../context/production/productionContext";
import Modal from "../component/Modal";
import Confirmation from "../component/Confirmation";

const ProductionCut = props => {
  const productionContext = useContext(ProductionContext);

  const {
    createProduct,
    initProducts,
    procProducts,
    setCurrentProduct,
    getInitProducts,
    getProcProducts,
    batchToWeigh,
    loading,
  } = productionContext;

  const currentMaterial = JSON.parse(localStorage.getItem("currentMaterial"));

  useEffect(() => {
    getInitProducts(currentMaterial.id);
    getProcProducts(currentMaterial.id);
    // eslint-disable-next-line
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <div>
                <Button text={currentMaterial.name} className="btn btn-prod" />
              </div>
              <div>
                <Button
                  text="Cut"
                  className="btn btn-prod"
                  onClick={() => createProduct(currentMaterial.id)}
                />
              </div>
              <div>
                {initProducts.length > 0 &&
                  initProducts.map(product => (
                    <div key={product.id}>
                      <Button
                        text={product.name}
                        className="btn btn-prod"
                        onClick={() => {
                          setCurrentProduct(product);
                          props.history.push("/production/product");
                        }}
                      />
                    </div>
                  ))}
              </div>
              <div>
                <hr />
                {procProducts.length > 0 &&
                  procProducts.map(product => (
                    <div key={product.id}>
                      <Button
                        text={product.name}
                        icon={<i className="fa-solid fa-circle-check"></i>}
                        className="btn btn-prod"
                        // onClick={() => {
                        //   setCurrentProduct(product);
                        //   props.history.push('/production/product');
                        // }}
                      />
                    </div>
                  ))}
              </div>
            </div>
            <div className="text-center">
              {!initProducts.length && (
                <Button
                  text="Finish"
                  className="btn btn-prod"
                  onClick={handleOpen}
                />
              )}
              <Link to="/production/cutlist">
                <Button text="Back" className="btn btn-prod" />
              </Link>
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              component={
                <Confirmation
                  msg="Material will be stock-out. Continue?"
                  onConfirm={() => {
                    batchToWeigh(currentMaterial.id);
                    props.history.push("/production/cutlist");
                  }}
                />
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductionCut;

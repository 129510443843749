import React, { useContext, useState } from "react";
import Button from "../component/Button";
import SupplierSelector from "../component/SupplierSelector";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import Alert from "../component/Alert";
import Camera from "../component/Camera";
import ImagesContext from "../context/images/imagesContext";

const WeighbridgeDocketCreate = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const { selectedSupplier } = supplierContext;
  const { createWeighbridgeDocket, to } = materialContext;
  const { currentImages, setCurrentImages } = imagesContext;

  // book in material data input
  const [materialData, setMaterialData] = useState({
    rego: "",
  });

  const { rego } = materialData;

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  // create weighbridge docket submit
  const createWeighbridgeDocketSubmit = () => {
    createWeighbridgeDocket(
      selectedSupplier.id,
      currentImages,
      rego,
      props,
      to,
      () => setCurrentImages([])
    );
  };

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center">
            <SupplierSelector proc="btn-mat" />
            {!selectedSupplier ? null : (
              <>
                {currentImages.length < 1 && (
                  <p>Please take photos of truck/trailer showing rego or tailer ID</p>
                )}
                <Camera proc="mat" />
                <div className="input-group">
                  <span className="input-fix-left">Rego:</span>
                  <input
                    className="input-fix"
                    type="text"
                    name="rego"
                    value={rego}
                    onChange={onChange}
                  />
                  <span className="input-fix-right"></span>
                </div>
                {currentImages.length > 0 && (
                  <Button
                    text="Create"
                    className="btn btn-mat"
                    onClick={createWeighbridgeDocketSubmit}
                  />
                )}
              </>
            )}
          </div>
          <div className="text-center">
            <Button
              text="Back"
              className="btn btn-mat"
              onClick={() => props.history.goBack()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default WeighbridgeDocketCreate;

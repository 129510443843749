import React, { useContext, useState } from "react";
import MaterialContext from "../context/material/materialContext";
import Modal from "../component/Modal";
import Button from "../component/Button";
import GradeList from "./GradeList";

export default function GradeSelector({ proc }) {
  const materialContext = useContext(MaterialContext);

  const { grade, getGrades } = materialContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={selectorSytle}>
      {!grade.name || grade.name === "undefined" ? (
        <Button
          className={`btn btn-${proc}`}
          text="Grade"
          onClick={async () => {
            await getGrades();
            handleOpen();
          }}
        />
      ) : (
        <Button
          className={`btn btn-${proc}`}
          text={grade.name}
          onClick={async () => {
            await getGrades();
            handleOpen();
          }}
        />
      )}
      <Modal open={open} onClose={handleClose} component={<GradeList />} />
    </div>
  );
}

const selectorSytle = {
  marginTop: "10px",
};

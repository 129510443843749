import {
  SET_CURRENT_PRODUCT,
  GET_PENDING_WEIGH,
  CONFIRM_PRODUCT,
  CREATE_NEW_PRODUCT,
  SET_INIT_PRODUCTS,
  SET_PROCESSING_PRODUCTS,
  STOCK_IN_PRODUCT,
  BATCH_TO_WEIGH,
  TOGGLE_JOINT,
  SET_LOADING,
  STOCK_PRODUCTS,
} from '../types';

const ProductionReducer = (state, action) => {
  switch (action.type) {
    case GET_PENDING_WEIGH:
      return { ...state, pendingWeigh: action.payload, loading: false };
    case SET_CURRENT_PRODUCT:
      localStorage.setItem('currentProduct', JSON.stringify(action.payload));
      return { ...state, currentProduct: action.payload, loading: false };
    case SET_INIT_PRODUCTS:
      return {
        ...state,
        initProducts: action.payload,
        loading: false,
      };
    case SET_PROCESSING_PRODUCTS:
      return {
        ...state,
        procProducts: action.payload,
        loading: false,
      };
    case BATCH_TO_WEIGH:
      return {
        ...state,
        procProducts: [],
        loading: false,
      };
    case STOCK_IN_PRODUCT:
    case CONFIRM_PRODUCT:
      return {
        ...state,
        currentProduct: {},
        loading: false,
      };
    case TOGGLE_JOINT:
      return {
        ...state,
        joint: action.payload,
      };
    case STOCK_PRODUCTS:
      return {
        ...state,
        stockProducts: action.payload,
      };
    case CREATE_NEW_PRODUCT:
      return {
        ...state,
        initProducts: [action.payload, ...state.initProducts],
        loading: false,
      };
    case SET_LOADING:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default ProductionReducer;

import {
  SET_LOADING,
  SET_CURRENT_IMAGES,
  DELETE_IMAGE,
  SET_PRELOAD_IMAGES,
  SET_MODAL_IMAGE,
  SET_MEMO_IMAGES,
  SET_IMAGE_STRING,
  DELETE_MEMO_IMAGE,
} from "../types";

const ImagesReducer = (state, action) => {
  switch (action.type) {
    case SET_CURRENT_IMAGES:
      return {
        ...state,
        currentImages: action.payload,
        loading: false,
      };
    case SET_IMAGE_STRING:
      return {
        ...state,
        imageString: action.payload,
        loading: false,
      };
    case SET_MODAL_IMAGE:
      return {
        ...state,
        modalImage: action.payload,
        loading: false,
      };
    case SET_MEMO_IMAGES:
      return {
        ...state,
        memoImages: action.payload,
        loading: false,
      };
    case SET_PRELOAD_IMAGES:
      console.log(action.payload);
      return {
        ...state,
        preLoadImages: action.payload,
        loading: false,
      };
    case DELETE_IMAGE:
      const filtered = state.currentImages.filter(
        image => image.name !== action.payload.name
      );
      return {
        ...state,
        currentImages: filtered,
        loading: false,
      };
    case DELETE_MEMO_IMAGE:
      const memofiltered = state.memoImages.filter(
        image => image.name !== action.payload.name
      );
      return {
        ...state,
        memoImages: memofiltered,
        loading: false,
      };
    case SET_LOADING:
      return { ...state, loadingImages: true };
    default:
      return state;
  }
};

export default ImagesReducer;

import React, { useContext } from "react";
import AlertContext from "../context/alert/alertContext";

const Alert = () => {
  const alertContext = useContext(AlertContext);

  const { alert } = alertContext;

  return (
    alert !== null && (
      <div className={`alert bg-${alert.type}`}>
        <i className="fa-solid fa-circle-exclamation"></i> {alert.msg}
      </div>
    )
  );
};

export default Alert;

import React, { useContext } from 'react';
import Button from '../component/Button';
import SupplierContext from '../context/supplier/supplierContext';
import MaterialContext from '../context/material/materialContext';
import ImagesContext from '../context/images/imagesContext';
import GradeSelector from '../component/GradeSelector';
import TypeSelector from '../component/TypeSelector';
import Camera from '../component/Camera';
import Alert from '../component/Alert';
import AlertContext from '../context/alert/alertContext';

const ConfirmMaterial = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const alertContext = useContext(AlertContext);

  const {
    // currentImages,
    currentMaterial,
    // setCurrentImages,
    type,
    grade,
    materialConfirm,
  } = materialContext;
  const { currentImages, setCurrentImages } = imagesContext;
  const { selectedSupplier } = supplierContext;
  const { setAlert } = alertContext;

  // setCurrentImages(process_records.images);

  const confirmMaterial = async () => {
    if (type !== '' && grade !== '') {
      await materialConfirm(
        currentMaterial.id,
        selectedSupplier.id,
        type,
        grade,
        currentImages
      );
      await setCurrentImages([]);
      console.log('Confirm arrival');
      props.history.push('/material/update');
    } else {
      setAlert(` Please set type and grade`, 'warning');
    }
  };

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center">
            <Button text={currentMaterial.name} className="btn btn-mat" />
            <div className="img-gallery">
              {!currentMaterial.process_records.length
                ? null
                : currentMaterial.process_records.map(record =>
                    record.images.map(image => (
                      <div key={image.id} className="img-gallery-item">
                        <img src={image.url} alt={image.id} />
                      </div>
                    ))
                  )}
            </div>
            <div>
              <TypeSelector proc="btn-mat" />
              <GradeSelector />
              <Camera setCurrentImages={setCurrentImages} />
            </div>
          </div>
          <div style={{ width: '200px', margin: '0 auto' }}>
            <Button
              text="Confirm"
              className="btn btn-mat"
              onClick={confirmMaterial}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmMaterial;

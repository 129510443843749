import React from "react";
import { useContext } from "react";
import MaterialContext from "../context/material/materialContext";
import Spinner from "./Spinner";

const TypeList = () => {
  const materialContext = useContext(MaterialContext);

  const { types, setType, loading } = materialContext;

  return (
    <>
      {loading || !types ? (
        <Spinner />
      ) : (
        <div className="supplier-grid" style={gridStyle}>
          <>
            {types.map(typeitem => (
              <button
                className="grid-item"
                key={typeitem.value}
                style={gridItemStyle}
                onClick={() => setType(typeitem)}
              >
                {typeitem.name}
              </button>
            ))}
          </>
        </div>
      )}
    </>
  );
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  margin: "0 auto",
};

const gridItemStyle = {
  height: "81px",
  border: "1px solid #fff",
  lineHeight: "75px",
};

export default TypeList;

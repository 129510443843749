import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import Button from '../component/Button';
import TypeSelector from '../component/TypeSelector';
import GradeSelector from '../component/GradeSelector';
import SupplierContext from '../context/supplier/supplierContext';
import MaterialContext from '../context/material/materialContext';
import ImagesContext from '../context/images/imagesContext';
import Camera from '../component/Camera';
import Alert from '../component/Alert';
import AlertContext from '../context/alert/alertContext';
import Spinner from '../component/Spinner';

const CreateMaterial = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const alertContext = useContext(AlertContext);

  const { currentImages, setCurrentImages } = imagesContext;

  const { materialIn, type, grade, loading } = materialContext;
  const { selectedSupplier } = supplierContext;
  const { setAlert } = alertContext;

  const createMaterial = async e => {
    e.preventDefault();
    if (type !== '' && grade !== '') {
      await materialIn(selectedSupplier.id, type, grade, currentImages);
      setCurrentImages([]);
      props.history.push('/material/update');
    } else {
      setAlert(` Please set type and grade`, 'warning');
    }
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="material">
            <div className="text-center">
              <Button text={selectedSupplier.name} className="btn btn-mat" />
              <div>
                <TypeSelector proc="mat" />
                <GradeSelector proc="mat" />
                <Camera proc="mat" setCurrentImages={setCurrentImages} />
              </div>
            </div>
            <div className="text-center">
              {currentImages.length > 0 && selectedSupplier ? (
                <Button
                  text="Create"
                  className="btn btn-mat"
                  onClick={createMaterial}
                />
              ) : (
                <Link to="/material">
                  <Button text="Back" className="btn btn-mat" />
                </Link>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CreateMaterial;

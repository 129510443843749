import React from 'react';
import PropTypes from 'prop-types';
import './Confirmation.css';

const Confirmation = ({ msg, onConfirm, onCancel }) => {
  return (
    <div className="confirmation-container">
      <div className="confirmation-popup">
        <div className="confirmation-info">{msg}</div>
        <div className="confirmation-buttons">
          <button
            className="btn-confirmation confirmation-y"
            onClick={onConfirm}
          >
            Yes
          </button>
          <button
            className="btn-confirmation confirmation-n"
            onClick={onCancel}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  msg: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

export default Confirmation;

import React, { useReducer } from "react";
import ImagesReducer from "./imagesReducer";
import ImagesContext from "./imagesContext";
import {
  SET_LOADING,
  SET_CURRENT_IMAGES,
  SET_MODAL_IMAGE,
  DELETE_IMAGE,
  SET_PRELOAD_IMAGES,
  SET_MEMO_IMAGES,
  SET_IMAGE_STRING,
  DELETE_MEMO_IMAGE,
} from "../types";

const ImagesState = props => {
  const initialState = {
    currentImages: [],
    preLoadImages: [],
    memoImages: [],
    imageString: "",
    loadingImages: false,
    modalImage: "",
  };

  const [state, dispatch] = useReducer(ImagesReducer, initialState);

  // set current image array in global state
  const setCurrentImages = images => {
    dispatch({ type: SET_CURRENT_IMAGES, payload: images });
  };

  // set modal image array in global state
  const setModalImage = image => {
    dispatch({ type: SET_MODAL_IMAGE, payload: image });
  };

  // set memo image array in global state
  const setMemoImages = images => {
    dispatch({ type: SET_MEMO_IMAGES, payload: images });
  };

  // set current image as preloaded products
  const setPreloadedImages = images => {
    console.log("preload images");
    dispatch({ type: SET_PRELOAD_IMAGES, payload: images });
  };

  // set image string in state
  const setImageString = imageString => {
    dispatch({ type: SET_IMAGE_STRING, payload: imageString });
  };

  // remove image from global state
  const deleteImage = image => {
    dispatch({ type: DELETE_IMAGE, payload: image });
  };

  // remove image from memo image global state
  const deleteMemoImage = image => {
    dispatch({ type: DELETE_MEMO_IMAGE, payload: image });
  };

  // set loading spinner
  const setLoading = () => dispatch({ type: SET_LOADING });

  // resize image
  var resizeImage = function (settings) {
    var file = settings.file;
    const filename = settings.filename;
    var maxSize = settings.maxSize;
    var reader = new FileReader();
    var image = new Image();
    var canvas = document.createElement("canvas");

    var dataURItoBlob = function (dataURI) {
      var bytes =
        dataURI.split(",")[0].indexOf("base64") >= 0
          ? atob(dataURI.split(",")[1])
          : unescape(dataURI.split(",")[1]);
      var mime = dataURI.split(",")[0].split(":")[1].split(";")[0];
      var max = bytes.length;
      var ia = new Uint8Array(max);
      for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
      const blob = new Blob([ia], { type: mime });
      return new File([blob], filename);
    };

    var resize = function () {
      var width = image.width;
      var height = image.height;
      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else {
        if (height > maxSize) {
          width *= maxSize / height;
          height = maxSize;
        }
      }
      canvas.width = width;
      canvas.height = height;
      canvas.getContext("2d").drawImage(image, 0, 0, width, height);
      var dataUrl = canvas.toDataURL("image/jpeg");
      // console.log(dataUrl);
      return dataURItoBlob(dataUrl);
    };

    return new Promise(function (ok, no) {
      reader.onload = function (readerEvent) {
        image.onload = function () {
          return ok(resize());
        };
        image.src = readerEvent.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  // resize image when it is attached (before uploading) function
  const resizeImageOnAttach = async image => {
    // console.log(image);
    const config = {
      file: image,
      filename: image.name,
      maxSize: 1024,
    };

    const resizedImage = await resizeImage(config);
    // console.log(resizedImage);

    return resizedImage;
  };

  return (
    <ImagesContext.Provider
      value={{
        currentImages: state.currentImages,
        preLoadImages: state.preLoadImages,
        memoImages: state.memoImages,
        loadingImages: state.loadingImages,
        modalImage: state.modalImage,
        imageString: state.imageString,
        setLoading,
        setCurrentImages,
        setModalImage,
        setMemoImages,
        setPreloadedImages,
        deleteImage,
        deleteMemoImage,
        setImageString,
        resizeImageOnAttach,
      }}
    >
      {props.children}
    </ImagesContext.Provider>
  );
};

export default ImagesState;

import React from "react";
import { useContext } from "react";
import MaterialContext from "../context/material/materialContext";
import Spinner from "./Spinner";

const HubList = () => {
  const materialContext = useContext(MaterialContext);

  const { reelHubList, setReelHub, loading } = materialContext;

  if (loading === true || !reelHubList) {
    return <Spinner />;
  }

  return (
    <div className="list">
      {reelHubList.map(reelHub => (
        <div
          key={reelHub.value}
          className="list-item"
          onClick={() => {
            setReelHub(reelHub);
          }}
        >
          {reelHub.name}
        </div>
      ))}
    </div>
  );
};

export default HubList;

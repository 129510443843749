import React, { useContext, useEffect, useState } from "react";
import Button from "../component/Button";
import ImagesContext from "../context/images/imagesContext";
import Modal from "../component/Modal";
import GalleryPhoto from "../component/GalleryPhoto";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import Signature from "../component/Signature";
import OrdersContext from "../context/orders/ordersContext";

const WeighbridgeDocketTabletExportPending = props => {
  const imagesContext = useContext(ImagesContext);
  const orderContext = useContext(OrdersContext);

  const { setModalImage, imageString, setImageString } = imagesContext;
  const {
    getPendingExportWeighbridgeDockets,
    pendingExportWBDockets,
    setCurrentContainer,
    currentContainer,
    readyContainers,
    currentOrder,
    currentWeighbridgeDocket,
    setCurrentWeighbridgeDocket,
    updateExportWeighbridgeDocket,
    loading,
  } = orderContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // rego input
  const [wbdata, setWbData] = useState({
    rego: "",
    driverName: "",
    transportCompany: "",
    firstGross: "",
    firstTare: "",
    remarks: "",
    nett: "",
  });

  const onChange = e =>
    setWbData({ ...wbdata, [e.target.name]: e.target.value });

  const { rego, driverName, transportCompany, firstGross, firstTare, remarks } =
    wbdata;

  useEffect(() => {
    getPendingExportWeighbridgeDockets(); //get pending export weighbridge dockets
    setImageString("");
    // eslint-disable-next-line
  }, []);

  const weighbridgeDocketSignSubmit = () => {
    updateExportWeighbridgeDocket(
      currentWeighbridgeDocket.id,
      currentWeighbridgeDocket.container_id
        ? currentWeighbridgeDocket.container_id
        : currentContainer.id,
      "completed",
      firstGross,
      firstTare,
      rego,
      remarks,
      transportCompany,
      driverName,
      imageString,
      () => {
        getPendingExportWeighbridgeDockets();
        props.history.push(`/create-export-weighbridge-docket/print`);
      }
    );
  };

  const weighbridgeDocketSaveDraft = () => {
    updateExportWeighbridgeDocket(
      currentWeighbridgeDocket.id,
      currentContainer ? currentContainer.id : "",
      "pending",
      firstGross,
      firstTare,
      rego,
      remarks,
      transportCompany,
      driverName,
      imageString,
      () => {
        setCurrentWeighbridgeDocket("");
        getPendingExportWeighbridgeDockets();
      }
    );
  };

  return (
    <>
      <Alert />
      <div className="container-t">
        <>
          {!currentWeighbridgeDocket ? (
            <>
              <div className="wb-list-title">
                <h3>
                  Pending Export Weighbridge Dockets for Order:{" "}
                  {currentOrder.order_no}
                </h3>
              </div>
              {!pendingExportWBDockets.length ? (
                <div className="wb-list-content">
                  <h3>No weighbridge dockets to sign off</h3>
                </div>
              ) : (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="wb-list">
                        {pendingExportWBDockets.map(wbdocket => (
                          <div
                            key={wbdocket.name}
                            className="wb-list-item-grid"
                            onClick={() => {
                              setCurrentWeighbridgeDocket(wbdocket);

                              setWbData({
                                ...wbdata,
                                firstGross: wbdocket.first_gross
                                  ? wbdocket.first_gross
                                  : "",
                                firstTare: wbdocket.first_tare
                                  ? wbdocket.first_tare
                                  : "",
                                rego: wbdocket.registration_no
                                  ? wbdocket.registration_no
                                  : "",
                                driverName: wbdocket.driver_name
                                  ? wbdocket.driver_name
                                  : "",
                                remarks: wbdocket.comment
                                  ? wbdocket.comment
                                  : "",
                                transportCompany: wbdocket.transport_company
                                  ? wbdocket.transport_company
                                  : "",
                              });
                            }}
                          >
                            <div className="wb-list-item-grid-gallery">
                              <div className="wb-list-item-grid-name">
                                {wbdocket.name}
                              </div>
                            </div>
                            <div className="wb-list-item-grid-desc">
                              <div className="wb-list-item-grid-remark">
                                Rego: {wbdocket.registration_no}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="wb-d-nav">
                        <Button
                          text="Back"
                          className="btn btn-prod"
                          onClick={() => {
                            props.history.push(
                              `/weighbridge-docket-tablet-export`
                            );
                          }}
                        />
                        <Button
                          text="Refresh"
                          className="btn btn-mat"
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            // weighbridge docket details
            <>
              <div className="wb-d-title">
                <h1>Weighbridge Docket</h1>
                <h3>
                  No.: {currentWeighbridgeDocket.name} From:{" "}
                  {currentWeighbridgeDocket.from}
                </h3>
              </div>
              <hr />
              <div className="wb-d">
                <div className="wb-d-l">
                  {currentWeighbridgeDocket.container_id ? (
                    <div className="wb-d-list">
                      <h3>Container ID.: {currentWeighbridgeDocket.id}</h3>
                      <div>
                        Tare Weight: {currentWeighbridgeDocket.cont_tare} Tn
                      </div>
                    </div>
                  ) : (
                    <>
                      {!currentContainer ? (
                        <div className="wb-d-list">
                          <div>
                            <h3>Select from completed containers:</h3>
                          </div>
                          {readyContainers.length > 0 ? (
                            <div className="wb-cont-list">
                              {readyContainers.map(container => (
                                <div
                                  key={container.id}
                                  className="list-item text-center"
                                  onClick={() => {
                                    setCurrentContainer(container);
                                  }}
                                >
                                  Container No.: {container.container_no}
                                </div>
                              ))}
                            </div>
                          ) : (
                            <div className="wb-d-item">
                              <p>
                                No completed container yet, please check with
                                workshop...
                              </p>
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className="wb-d-list">
                            <h3>
                              Container No.: {currentContainer.container_no}
                            </h3>
                            <div>
                              Tare Weight: {currentContainer.init_weight} Tn
                            </div>
                            <div>
                              Products Loaded Qty:{" "}
                              {currentContainer.total_products_count}
                            </div>
                            <div>
                              Total Products Weight:{" "}
                              {currentContainer.total_products_weight} Tn
                              <div>Seal number: {currentContainer.seal}</div>
                            </div>
                            <Button
                              text="Choose another"
                              className="btn btn-mat"
                              onClick={() => setCurrentContainer("")}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                  <hr />
                  <div className="wb-d-list">
                    <div className="grid-col-2">
                      <span className="input-label">First Gross (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstGross"
                        value={firstGross}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">First Tare (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstTare"
                        value={firstTare}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">
                        Registration/Trailer ID:{" "}
                      </span>
                      <input
                        className="input-text"
                        type="text"
                        name="rego"
                        value={rego}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">Remarks: </span>
                      <input
                        className="input-text"
                        type="text"
                        name="remarks"
                        value={remarks}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="wb-d-r">
                  <div className="wb-d-gallery">
                    {currentContainer ? (
                      currentContainer.process_records.map(record => (
                        <div
                          className="wb-list-item-grid-image"
                          key={record.name}
                          onClick={() => {
                            setModalImage(record.images[0]);
                            handleOpen();
                          }}
                        >
                          <img
                            src={record.images[0].url}
                            alt={record.images[0].id}
                          />
                          {record.name}
                        </div>
                      ))
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="wb-d-signature">
                    <hr />
                    <div className="px-10">
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Transport Company: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="transportCompany"
                          value={
                            transportCompany
                              ? transportCompany
                              : currentOrder.transport_company
                          }
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Driver's Name: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="driverName"
                          value={driverName}
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                    </div>
                    {currentWeighbridgeDocket.signature_image ? (
                      <>
                        <div className="sigContainer">
                          <img
                            alt="signature uploaded"
                            style={{ height: "100%" }}
                            src={currentWeighbridgeDocket.signature_image}
                          ></img>
                        </div>
                      </>
                    ) : (
                      <Signature penColor={"black"} />
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="wb-d-nav">
                <Button
                  text="Back"
                  className="btn btn-mat"
                  onClick={() => setCurrentWeighbridgeDocket("")}
                />
                {rego ? (
                  <Button
                    text="Save Draft"
                    className="btn btn-prod"
                    onClick={() => weighbridgeDocketSaveDraft()}
                  />
                ) : (
                  <Button
                    text="Save Draft"
                    className="btn btn-prod btn-disabled"
                  />
                )}
                {((imageString || currentWeighbridgeDocket.signature_image) &&
                  currentContainer) ||
                currentWeighbridgeDocket.container_id ? (
                  <Button
                    text="Submit"
                    className="btn btn-mat"
                    onClick={() => weighbridgeDocketSignSubmit()}
                  />
                ) : (
                  <Button text="Submit" className="btn btn-mat btn-disabled" />
                )}
              </div>
            </>
          )}
        </>
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        onClose={handleClose}
        component={<GalleryPhoto isEdit={false} />}
      />
    </>
  );
};

export default WeighbridgeDocketTabletExportPending;

import React, { useContext, useState } from "react";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Camera from "../component/Camera";
import Alert from "../component/Alert";
import AlertContext from "../context/alert/alertContext";
import Spinner from "../component/Spinner";
import Confirmation from "../component/Confirmation";
import Modal from "../component/Modal";
import HubSelector from "../component/HubSelector";

const UpdateMaterial = props => {
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const alertContext = useContext(AlertContext);

  const {
    autoCreateProduct,
    materialUpdate,
    to,
    reelHub,
    loading,
    updateWeighbridgeDocketStatus,
    currentWeighbridgeDocket,
  } = materialContext;
  const { currentImages, setCurrentImages } = imagesContext;
  const { setAlert } = alertContext;

  const selectedSupplier = JSON.parse(localStorage.getItem("selectedSupplier"));
  const currentMaterial = JSON.parse(localStorage.getItem("currentMaterial"));
  const { name, id, grade, category } = currentMaterial;

  const [materialData, setMaterialData] = useState({
    thickness: currentMaterial.thickness,
    width: currentMaterial.width,
    weight: currentMaterial.weight,
    diameter: currentMaterial.diameter,
  });

  const { thickness, width, weight, diameter } = materialData;

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  const updateMaterial = () => {
    if (thickness !== "" && width !== "" && weight !== "") {
      materialUpdate(
        selectedSupplier.id,
        id,
        category,
        grade,
        thickness,
        width,
        weight,
        reelHub,
        currentImages,
        () => setCurrentImages([])
      );
    } else {
      setAlert(` Please fill in measurement data`, "warning");
    }
  };

  const weighbridgeDocketSignSubmit = () => {
    updateWeighbridgeDocketStatus(
      currentWeighbridgeDocket.id,
      selectedSupplier.id,
      true
    );
  };

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [openProd, setOpenProd] = useState(false);
  const handleOpenProd = () => {
    setOpenProd(true);
  };
  const handleCloseProd = () => {
    setOpenProd(false);
  };

  const [openWB, setOpenWB] = useState(false);
  const handleOpenWB = () => setOpenWB(true);

  const directProductIn = () => {
    autoCreateProduct(currentMaterial.id);
    handleOpenWB();
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="material">
            <div className="text-center">
              <Button text={name} className="btn btn-mat" />
              <div className="img-gallery">
                {!currentMaterial.process_records.length
                  ? null
                  : currentMaterial.process_records.map(record =>
                      record.images.map(image => (
                        <div key={image.id} className="img-gallery-item">
                          <img src={image.url} alt={image.id} />
                        </div>
                      ))
                    )}
              </div>
              <hr />
              {!currentImages.length && (
                <p>Please take a photo of material with markings</p>
              )}
              <Camera setCurrentImages={setCurrentImages} proc="mat" />
              <hr />
              <HubSelector proc="mat" />
            </div>
            <div style={{ width: "200px", margin: "0 auto" }}>
              <div className="input-group">
                <span className="input-fix-left">Thickness</span>
                <input
                  className="input-fix"
                  type="number"
                  name="thickness"
                  value={thickness}
                  onChange={onChange}
                />
                <span className="input-fix-right">mm</span>
              </div>
              <div className="input-group">
                <span className="input-fix-left">Width</span>
                <input
                  className="input-fix"
                  type="number"
                  name="width"
                  value={width}
                  onChange={onChange}
                />
                <span className="input-fix-right">mm</span>
              </div>
              <div className="input-group">
                <span className="input-fix-left">Weight</span>
                <input
                  className="input-fix"
                  type="number"
                  name="weight"
                  value={weight}
                  onChange={onChange}
                />
                <span className="input-fix-right">t</span>
              </div>
              {category.name === "steel" ? (
                <div className="input-group">
                  <span className="input-fix-left">Diameter</span>
                  <input
                    className="input-fix"
                    type="number"
                    name="diameter"
                    value={diameter}
                    onChange={onChange}
                  />
                  <span className="input-fix-right">mm</span>
                </div>
              ) : null}
              {!thickness ||
              !width ||
              parseFloat(weight) === 0 ||
              !weight ||
              !currentImages.length ? (
                <Button text="Need Data" className="btn btn-mat btn-disabled" />
              ) : (
                <Button
                  text="Stock In"
                  className="btn btn-mat"
                  onClick={handleOpen}
                />
              )}
              <Button
                text="Back"
                className="btn btn-mat"
                onClick={() => props.history.goBack()}
              />
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              component={
                <Confirmation
                  msg="Need cutting?"
                  onConfirm={() => {
                    updateMaterial();
                    handleOpenWB();
                  }}
                  onCancel={() => {
                    updateMaterial();
                    handleOpenProd();
                  }}
                />
              }
            />
            <Modal
              open={openProd}
              onClose={handleCloseProd}
              component={
                <Confirmation
                  msg={`${name}-1 will be created as Product ID, Do you wish to proceed?`}
                  onConfirm={directProductIn}
                  onCancel={() => {
                    updateMaterial();
                    handleCloseProd();
                    handleOpenWB();
                  }}
                />
              }
            />
            <Modal
              open={openWB}
              component={
                <Confirmation
                  msg={`Is this only/last belt on this truck?`}
                  onConfirm={function () {
                    if (currentWeighbridgeDocket.signed === true) {
                      weighbridgeDocketSignSubmit();
                    }
                    props.history.push("/");
                  }}
                  onCancel={() => props.history.push(`/${to}`)}
                />
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UpdateMaterial;

import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Camera from "../component/Camera";
import OrdersContext from "../context/orders/ordersContext";
import Button from "../component/Button";
import ImagesContext from "../context/images/imagesContext";
// import Modal from "../component/Modal";
// import Confirmation from "../component/Confirmation";

const OrderDetails = props => {
  const ordersContext = useContext(OrdersContext);
  const imagesContext = useContext(ImagesContext);
  const {
    createContainer,
    getContainers,
    containers,
    setCurrentContainer,
    // completeOrder,
  } = ordersContext;
  const { currentImages } = imagesContext;

  const currentOrder = JSON.parse(localStorage.getItem("currentOrder"));

  // get containers list
  useEffect(() => {
    getContainers(currentOrder.order_no);
    // eslint-disable-next-line
  }, []);

  const [containerData, setContainerData] = useState({
    containerNumber: "",
    weight: "",
  });

  const { containerNumber, weight } = containerData;

  const onChange = e =>
    setContainerData({ ...containerData, [e.target.name]: e.target.value });

  // toggle create-container form show/hide
  const [childVisible, setChildVisible] = useState(false);

  // toggle order details panel show/hide
  const [orderDetailsVisible, setOrderDetailsVisible] = useState(false);

  const toggleCreateContainer = () => {
    createContainerElement();
    setChildVisible(!childVisible);
  };

  const toggleOrderDetails = () => {
    orderDetailsElement();
    setOrderDetailsVisible(!orderDetailsVisible);
  };

  const createContainerSubmit = async () => {
    await createContainer(
      currentOrder.order_no,
      containerNumber,
      weight,
      currentImages
    );
    await props.history.push("/orders/container");
  };

  // const completeOrderSubmit = async () => {
  //   await completeOrder(currentOrder.order_no);
  //   await props.history.push("/orders");
  // };

  // // handle modal open/close
  // const [open, setOpen] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  // create-container form
  const createContainerElement = () => {
    return (
      <div className="create-container">
        <div className="input-group">
          <input
            className="input-text"
            type="text"
            name="containerNumber"
            placeholder="Container Number"
            value={containerNumber}
            onChange={onChange}
            autoComplete="off"
          />
          <input
            className="input-text"
            type="number"
            name="weight"
            placeholder="Self-weight (Tons)"
            value={weight}
            onChange={onChange}
            autoComplete="off"
          />
          {!currentImages.length && (
            <p>
              Please add pictures of empty container, both outside and inside
            </p>
          )}
          <Camera proc="order" />
          {currentImages.length && containerNumber !== "" ? (
            <Button
              text="Create"
              className="btn btn-order"
              onClick={createContainerSubmit}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  };

  // order details panel
  const orderDetailsElement = () => {
    return (
      <div className="create-container">
        <ul className="list">
          <li className="list-item">Cut-off date: 26/12/2021</li>
          <li className="list-item">ETD: 26/12/2021</li>
          <li className="list-item">Ctner Qty: 20*20GP</li>
          <li className="list-item">Belt Type: Fabric</li>
        </ul>
      </div>
    );
  };

  return (
    <>
      <div className="container">
        <div className="orders">
          <div>
            <Button
              text={currentOrder.order_no}
              className="btn btn-order"
              onClick={toggleOrderDetails}
            />
            {orderDetailsVisible ? orderDetailsElement() : ""}
            {containers && (
              <div className="order-containers">
                {containers.map(container => {
                  if (container.status === "sealed") {
                    return (
                      <div key={container.id}>
                        <Button
                          text={container.container_no}
                          className="btn btn-order"
                          icon={<i className="fa-solid fa-circle-check"></i>}
                        />
                      </div>
                    );
                  } else {
                    return (
                      <div key={container.id}>
                        <Button
                          text={container.container_no}
                          className="btn btn-order"
                          onClick={() => {
                            setCurrentContainer(container);
                            props.history.push("/orders/container");
                          }}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            )}

            <Button
              text="Create Container"
              className="btn btn-order"
              onClick={toggleCreateContainer}
            />
            {childVisible ? createContainerElement() : ""}
          </div>
          <div>
            {/* {containers.some(container => container.status !== "sealed") ||
            !containers.length ? (
              ""
            ) : (
              <Button
                text="Complete Order"
                className="btn btn-order"
                onClick={handleOpen}
              />
            )} */}
            <Link to="/orders">
              <Button text="Back" className="btn btn-order" />
            </Link>
          </div>
        </div>
      </div>
      {/* <Modal
        open={open}
        onClose={handleClose}
        component={
          <Confirmation
            msg={`Completing order #${currentOrder.order_no}, Do you wish to proceed?`}
            onConfirm={() => completeOrderSubmit()}
            onCancel={() => {
              handleClose();
            }}
          />
        }
      /> */}
    </>
  );
};

export default OrderDetails;

import React from "react";
import { Link } from "react-router-dom";
import Button from "../component/Button";

const WeighbridgeDocketTabletMain = props => {
  return (
    <>
      <div className="container">
        <div className="landing-buttons">
          <Link to="/weighbridge-docket-tablet-export">
            <Button text="Export WBs" className="btn btn-order" />
          </Link>
          <Link to="/weighbridge-docket-tablet">
            <Button text="Incoming WBs" className="btn btn-prod" />
          </Link>
        </div>
      </div>
    </>
  );
};
export default WeighbridgeDocketTabletMain;

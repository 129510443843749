import { Link } from "react-router-dom";
import Button from "../component/Button";

const BookInMenu = () => {
  return (
    <>
      <div className="container">
        <div className="landing-buttons">
          <Link to="/book-in-scan">
            <Button className="btn btn-mat" text="Scan QR Code" />
          </Link>
          {/* <Link to="/book-in">
            <Button className="btn btn-mat" text="Manual Book In" />
          </Link> */}
          <Link to="/">
            <Button className="btn btn-mat" text="Back" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default BookInMenu;

import React, { useContext, useEffect, useState } from "react";
import Alert from "../component/Alert";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import SupplierContext from "../context/supplier/supplierContext";
import SupplierSelector from "../component/SupplierSelector";
import Spinner from "../component/Spinner";
import ProductionContext from "../context/production/productionContext";

const OrderAddProduct = props => {
  const supplierContext = useContext(SupplierContext);
  const productionContext = useContext(ProductionContext);
  const materialContext = useContext(MaterialContext);

  const {
    getProductedMaterial,
    loading,
    productedMaterial,
    setCurrentMaterial,
  } = materialContext;
  const { getStockProducts } = productionContext;
  const { selectedSupplier } = supplierContext;
  useEffect(() => {
    selectedSupplier && getProductedMaterial(selectedSupplier.id);
    // eslint-disable-next-line
  }, [selectedSupplier]); //keep refreshing

  const [keyword, setKeyword] = useState("");

  const search = e => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="orders">
            <div className="text-center">
              <SupplierSelector proc="btn-order" />
              {!selectedSupplier ? null : (
                <div>
                  <div className="form-control">
                    <input
                      className="input-text"
                      name="filter"
                      type="text"
                      placeholder="Filter material..."
                      value={keyword}
                      onChange={search}
                      autoComplete="off"
                    />
                  </div>
                  {!productedMaterial.length ? (
                    "No processed material, please check other suppliers"
                  ) : (
                    <div>
                      <div>Producted Material:</div>
                      <hr />
                      <div className="list">
                        {productedMaterial
                          .filter(
                            material =>
                              material.name
                                .toUpperCase()
                                .includes(keyword.toUpperCase()) ||
                              keyword === ""
                          )
                          .map(material => (
                            <div
                              key={material.name}
                              className="list-item"
                              onClick={async () => {
                                await getStockProducts(material.id);
                                await setCurrentMaterial(material);
                                await props.history.push(`/orders/material`);
                              }}
                            >
                              {material.name} Grade: {material.grade.value}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="text-center">
              <Button
                text="Back"
                className="btn btn-order"
                onClick={() => {
                  props.history.push("/orders/container");
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default OrderAddProduct;

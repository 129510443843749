import React from "react";
import { useContext } from "react";
import MaterialContext from "../context/material/materialContext";
import Spinner from "./Spinner";

const GradeList = () => {
  const materialContext = useContext(MaterialContext);

  const { grades, setGrade, loading } = materialContext;

  if (loading === true || !grades) {
    return <Spinner />;
  }

  return (
    <div className="supplier-grid" style={gridStyle}>
      {grades.map(gradeitem => (
        <button
          className="grid-item"
          key={gradeitem.value}
          style={gridItemStyle}
          onClick={() => setGrade(gradeitem)}
        >
          {gradeitem.name}
        </button>
      ))}
    </div>
  );
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  margin: "0 auto",
};

const gridItemStyle = {
  height: "81px",
  border: "1px solid #fff",
  lineHeight: "75px",
};

export default GradeList;

import React, { useContext } from "react";
import Button from "../component/Button";
import SignaturePad from "react-signature-canvas";
import ImagesContext from "../context/images/imagesContext";
import "./Signature.css";

const Signature = () => {
  const imagesContext = useContext(ImagesContext);
  const { setImageString, imageString } = imagesContext;

  let sigPad = {};

  const clear = () => {
    sigPad.clear();
    setImageString("");
  };

  const getImage = () => {
    const canvas = sigPad.getCanvas().toDataURL();
    setImageString(canvas);
  };

  return (
    <>
      <div className="sigContainer">
        {!imageString && <div className="sigContainerWaterMark">Sign Here</div>}
        <SignaturePad
          onEnd={() => {
            getImage();
          }}
          onBegin={() => setImageString("1")}
          canvasProps={{ className: "sigPad" }}
          ref={ref => {
            sigPad = ref;
          }}
        />
      </div>
      <div className="signature-pad-buttons">
        {imageString ? (
          <Button
            text="Clear signature"
            className="btn btn-mat"
            onClick={clear}
          />
        ) : (
          <Button text="Clear signature" className="btn btn-mat btn-disabled" />
        )}
      </div>
    </>
  );
};

export default Signature;

import React, { useContext, useState } from "react";
import MaterialContext from "../context/material/materialContext";
import Modal from "../component/Modal";
import Button from "../component/Button";
import TypeList from "./TypeList";
import PropTypes from "prop-types";

export default function TypeSelector({ proc }) {
  const materialContext = useContext(MaterialContext);

  const { type, getTypes } = materialContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={selectorSytle}>
      {!type.name || type.name === "undefined" ? (
        <Button
          className={`btn btn-${proc}`}
          text="Type"
          onClick={async () => {
            await getTypes();
            handleOpen();
          }}
        />
      ) : (
        <Button
          className={`btn btn-${proc}`}
          text={type.name}
          onClick={async () => {
            await getTypes();
            handleOpen();
          }}
        />
      )}
      <Modal open={open} onClose={handleClose} component={<TypeList />} />
    </div>
  );
}

TypeSelector.propTypes = {
  proc: PropTypes.string,
};

const selectorSytle = {
  marginTop: "10px",
};

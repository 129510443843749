import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";

const Material = props => {
  const materialContext = useContext(MaterialContext);

  const {
    getConfirmedBookIns,
    getPendingBookIns,
    setCurrentBookIn,
    pendingBookIn,
    confirmedBookIn,
    loading,
    currentWeighbridgeDocket,
  } = materialContext;

  const [listType, setListType] = useState("pending"); // new state for tracking the list type
  const selectedSupplier = JSON.parse(localStorage.getItem("selectedSupplier"));

  useEffect(() => {
    getConfirmedBookIns(selectedSupplier.id);
    getPendingBookIns(selectedSupplier.id);
    // eslint-disable-next-line
  }, []);

  const toggleListType = () => {
    setListType(prevType => (prevType === "pending" ? "confirmed" : "pending"));
  };

  const bookInsList = listType === "pending" ? pendingBookIn : confirmedBookIn;

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center">
            <Button text={selectedSupplier.name} className="btn btn-mat" />
            {!selectedSupplier ? null : (
              <div>
                    <p>Adding to {currentWeighbridgeDocket.name}</p>
                    <Button
                      text={`${listType === "pending" ? "Pending" : "Confirmed"} Book-ins`}
                      icon={<i className="fa-solid fa-arrows-rotate"></i>}
                      className="btn btn-mat"
                      onClick={toggleListType}
                    />
                {!bookInsList.length ? (
                  <p>No {listType} book-in under {selectedSupplier.name}</p>
                ) : (
                  <div>
  
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="list">
                        {bookInsList.map(bookIn => (
                          <div
                            key={bookIn.name}
                            className="list-item"
                            onClick={async () => {
                              await setCurrentBookIn(bookIn);
                              props.history.push(`/material/bookindetails`);
                            }}
                          >
                            {bookIn.name}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
          <div className="text-center">
            <Link to="/weighbridge-docket">
              <Button text="Back" className="btn btn-mat" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Material;

import { GET_ORDERS, SET_ORDER, SET_CONTAINER } from '../types';

const MemoReducer = (state, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.payload,
      };
    case SET_CONTAINER:
      return {
        ...state,
        currentContainer: action.payload,
      };
    default:
      return state;
  }
};

export default MemoReducer;

import React, { useContext } from "react";
import Button from "../component/Button";
import TasksContext from "../context/tasks/tasksContext";

const Tasks = props => {
  const tasksContext = useContext(TasksContext);

  const { getTask } = tasksContext;

  const myTasks = JSON.parse(localStorage.getItem("tasks"));
  const setCurrentTask = async id => {
    await getTask(id, () => props.history.push("/tasks/taskdetails"));
  };

  return (
    <>
      <div className="container">
        <div className="orders">
          <div>
            <>
              <Button text="Tasks" className="btn btn-mat" />
            </>

            <div className="taskslist">
              <Button text="Pending Tasks" className="btn btn-mat" />
              {myTasks && (
                <div className="list">
                  {myTasks.map(task => (
                    <div
                      className="list-item"
                      key={task.id}
                      onClick={() => {
                        setCurrentTask(task.id);
                      }}
                    >
                      {task.is_completed && (
                        <span style={{ color: "green" }}>
                          <i className="fa-solid fa-circle-check"></i>
                        </span>
                      )}{" "}
                      {task.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <Button
              text="Back"
              className="btn btn-mat"
              onClick={() => props.history.goBack()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Tasks;

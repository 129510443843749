import React, { useContext, useEffect } from "react";
import ProductionContext from "../context/production/productionContext";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import SupplierSelector from "../component/SupplierSelector";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import { Link } from "react-router-dom";
import Button from "../component/Button";

const ProductionCutList = props => {
  const productionContext = useContext(ProductionContext);
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);

  const { loading } = productionContext;
  const { selectedSupplier } = supplierContext;
  const { stockMaterial, getCurrentMaterial, getStockMaterial } =
    materialContext;

  useEffect(() => {
    selectedSupplier && getStockMaterial(selectedSupplier.id);
    // eslint-disable-next-line
  }, [selectedSupplier]); //keep refreshing

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <SupplierSelector proc="btn-prod" />
              {!selectedSupplier ? null : (
                <div>
                  {!stockMaterial.length ? (
                    "No pending Material, please check other suppliers"
                  ) : (
                    <div>
                      <div className="list">
                        {stockMaterial.map(material => (
                          <div
                            key={material.name}
                            className="list-item"
                            onClick={() => {
                              const route = material.need_precut
                                ? "precut"
                                : "cut";
                              getCurrentMaterial(
                                selectedSupplier.id,
                                material.id,
                                () => props.history.push(`/production/${route}`)
                              );
                            }}
                          >
                            {material.name}
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="text-center">
              <Link to="/production">
                <Button text="Back" className="btn btn-prod" />
              </Link>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductionCutList;

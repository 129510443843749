import React, { useContext, useEffect, useState } from "react";
import Button from "../component/Button";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Modal from "../component/Modal";
import GalleryPhoto from "../component/GalleryPhoto";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import Signature from "../component/Signature";

// incoming wbdockets page
const WeighbridgeDocketTablet = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const {
    getPendingWeighbridgeDockets,
    getPendingWeighbridgeDocket,
    pendingWeighbridgeDockets,
    setCurrentWeighbridgeDocket,
    currentWeighbridgeDocket,
    updateWeighbridgeDocket,
    loading,
    // setTo,
  } = materialContext;
  const { setModalImage, currentImages, imageString } = imagesContext;
  const { setSupplier, selectedSupplier } = supplierContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // rego input
  const [wbdata, setWbData] = useState({
    rego: "rego",
    driverName: "",
    transportCompany: "",
    firstGross: "",
    firstTare: "",
    nett: "",
  });

  const onChange = e =>
    setWbData({ ...wbdata, [e.target.name]: e.target.value });

  const { rego, driverName, transportCompany, firstGross, firstTare } = wbdata;

  useEffect(() => {
    getPendingWeighbridgeDockets(); //get pending weighbridge dockets
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    currentWeighbridgeDocket &&
      setWbData({
        ...wbdata,
        rego: currentWeighbridgeDocket.registration_no,
        transportCompany: currentWeighbridgeDocket.transport_company
          ? currentWeighbridgeDocket.transport_company
          : "",
        driverName: currentWeighbridgeDocket.driver_name
          ? currentWeighbridgeDocket.driver_name
          : "",
      });
    // eslint-disable-next-line
  }, [currentWeighbridgeDocket]);

  const weighbridgeDocketSignSubmit = () => {
    updateWeighbridgeDocket(
      currentWeighbridgeDocket.id,
      selectedSupplier.id,
      rego,
      firstGross,
      firstTare,
      driverName,
      transportCompany,
      currentImages,
      imageString,
      () => {
        setCurrentWeighbridgeDocket("");
        getPendingWeighbridgeDockets();
      }
    );
  };

  return (
    <>
      <Alert />
      <div className="container-t">
        <>
          {!currentWeighbridgeDocket ? (
            <>
              <div className="wb-list-title">
                <h3>Pending Incoming Weighbridge Dockets</h3>
              </div>
              {loading ? (
                <Spinner />
              ) : (
                <>
                  {!pendingWeighbridgeDockets.length ? (
                    <div className="wb-list-content">
                      <h3>No weighbridge dockets to sign off</h3>
                    </div>
                  ) : (
                    <>
                      <div className="wb-list">
                        {pendingWeighbridgeDockets.map(wbdocket => (
                          <div
                            key={wbdocket.name}
                            className="wb-list-item-grid"
                            onClick={() => {
                              getPendingWeighbridgeDocket(wbdocket.id);
                              setSupplier(
                                wbdocket.supplier_id,
                                wbdocket.supplier_name
                              );
                            }}
                          >
                            <div className="wb-list-item-grid-gallery">
                              {wbdocket.images.map(image => (
                                <div
                                  className="wb-list-item-grid-image"
                                  key={image.id}
                                  // onClick={() => {
                                  //   setModalImage(image);
                                  //   handleOpen();
                                  // }}
                                >
                                  <img src={image.url} alt={image.id} />
                                </div>
                              ))}
                            </div>
                            <div className="wb-list-item-grid-desc">
                              <div className="wb-list-item-grid-name">
                                {wbdocket.name}
                              </div>
                              <div className="wb-list-item-grid-remark">
                                From: {wbdocket.supplier_name}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="wb-d-nav">
                        <Button
                          text="Back"
                          className="btn btn-prod"
                          onClick={() =>
                            props.history.push(
                              `/weighbridge-docket-tablet-main`
                            )
                          }
                        />
                        <Button
                          text="Refresh"
                          className="btn btn-mat"
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            // weighbridge docket details
            <>
              <div className="wb-d-title">
                <h1>Weighbridge Docket</h1>
                <h3>
                  No.: {currentWeighbridgeDocket.name} From:{" "}
                  {currentWeighbridgeDocket.supplier_name}
                </h3>
              </div>
              <hr />
              <div className="wb-d">
                <div className="wb-d-l">
                  <div className="wb-d-list">
                    <div>
                      <h3>Material recorded:</h3>
                    </div>
                    {currentWeighbridgeDocket.bookins.length > 0 ? (
                      <div className="grid-col-2">
                        {currentWeighbridgeDocket.bookins.map(bookin => (
                          <div key={bookin.id} className="wb-d-item">
                            {bookin.name}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="wb-d-item">
                        <p>No material recorded yet, please wait...</p>
                      </div>
                    )}
                    <div>
                      <h3>
                        Total Qty:{" "}
                        {currentWeighbridgeDocket.total_material_count}
                      </h3>
                    </div>
                    <div>
                      <h3>
                        Total Weight:{" "}
                        {currentWeighbridgeDocket.total_material_weight} Tn
                      </h3>
                    </div>
                  </div>
                  <hr />
                  <div className="wb-d-list">
                    <div className="grid-col-2">
                      <span className="input-label">First Gross (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstGross"
                        value={firstGross}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">First Tare (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstTare"
                        value={firstTare}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">
                        Registration/Trailer ID:{" "}
                      </span>
                      <input
                        className="input-text"
                        type="text"
                        name="rego"
                        value={rego}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="wb-d-r">
                  <div className="wb-d-gallery">
                    {currentWeighbridgeDocket.images.length > 0 &&
                      currentWeighbridgeDocket.images.map(image => (
                        <div
                          className="wb-list-item-grid-image"
                          key={image.id}
                          onClick={() => {
                            setModalImage(image);
                            handleOpen();
                          }}
                        >
                          <img src={image.url} alt={image.id} />
                        </div>
                      ))}
                  </div>
                  <div className="wb-d-signature">
                    <hr />
                    <div className="px-10">
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Driver's Name: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="driverName"
                          value={driverName}
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Transport Company: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="transportCompany"
                          value={transportCompany}
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                    </div>
                    {currentWeighbridgeDocket.signed === true ? (
                      <>
                        <div className="sigContainer">
                          <img
                            width="500"
                            height="200"
                            alt="signature uploaded"
                            style={{ height: "100%" }}
                            src={currentWeighbridgeDocket.signature_image}
                          ></img>
                        </div>
                      </>
                    ) : (
                      <Signature penColor={"black"} />
                    )}
                  </div>
                </div>
              </div>
              <hr />
              <div className="wb-d-nav">
                <Button
                  text="Back"
                  className="btn btn-mat"
                  onClick={() => setCurrentWeighbridgeDocket("")}
                />
                {imageString || currentWeighbridgeDocket.signature_image ? (
                  <Button
                    text="Submit"
                    className="btn btn-mat"
                    onClick={() => weighbridgeDocketSignSubmit()}
                  />
                ) : (
                  <Button text="Submit" className="btn btn-mat btn-disabled" />
                )}
              </div>
            </>
          )}
        </>
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        onClose={handleClose}
        component={<GalleryPhoto isEdit={false} />}
      />
    </>
  );
};

export default WeighbridgeDocketTablet;

import React, { useReducer } from "react";
import SupplierReducer from "./supplierReducer";
import SupplierContext from "./supplierContext";
import { GET_SUPPLIERS, SET_LOADING, SET_SUPPLIER } from "../types";
import axios from "axios";

const SupplierState = props => {
  const initialState = {
    suppliers: JSON.parse(localStorage.getItem("suppliers")),
    selectedSupplier: null,
    loading: false,
  };

  const [state, dispatch] = useReducer(SupplierReducer, initialState);

  // get supplier list from server
  const getSuppliers = async () => {
    setLoading();

    const endpoint = "api/v1/suppliers";

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (res.status === 200) {
        dispatch({ type: GET_SUPPLIERS, payload: res.data.suppliers });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setSupplier = (id, name) => {
    dispatch({ type: SET_SUPPLIER, payload: { id, name } });
  };

  const unsetSupplier = () => {
    dispatch({ type: SET_SUPPLIER, payload: "" });
  };

  // show loading spinner
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <SupplierContext.Provider
      value={{
        suppliers: state.suppliers,
        selectedSupplier: state.selectedSupplier,
        getSuppliers,
        setSupplier,
        setLoading,
        unsetSupplier,
      }}
    >
      {props.children}
    </SupplierContext.Provider>
  );
};

export default SupplierState;

import React, { useContext, useEffect } from "react";
import Button from "../component/Button";
import { Link } from "react-router-dom";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import OrdersContext from "../context/orders/ordersContext";

const WeighbridgeDocketTabletExport = props => {
  const orderContext = useContext(OrdersContext);

  const {
    orders,
    setCurrentOrder,
    getOrders,
    currentOrder,
    getContainers,
    setCurrentContainer,
    loading,
  } = orderContext;

  useEffect(() => {
    getOrders(); //get current orders
    setCurrentContainer();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Alert />
      <div className="container-t">
        <>
          {!currentOrder ? (
            <>
              <div className="wb-list-title">
                <h3>Select export order for this weighbridge docket:</h3>
              </div>
              {loading ? (
                <Spinner />
              ) : !orders.length ? (
                <div className="wb-list-content">
                  <h3>No current export orders</h3>
                </div>
              ) : (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="wb-list">
                        {orders.map(order => (
                          <div
                            className="list-item text-center"
                            key={order.order_no}
                            onClick={() => {
                              setCurrentOrder(order);
                              getContainers(order.order_no);
                            }}
                          >
                            {order.order_no}
                          </div>
                        ))}
                      </div>
                      <div className="wb-d-nav">
                        <Button
                          text="Back"
                          className="btn btn-prod"
                          onClick={() =>
                            props.history.push(
                              `/weighbridge-docket-tablet-main`
                            )
                          }
                        />
                        <Button
                          text="Refresh"
                          className="btn btn-mat"
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            <div className="container">
              <div className="landing-buttons">
                <h3>Weighbridge Dockets for order: {currentOrder.order_no}</h3>
                <Link to="/create-export-weighbridge-docket">
                  <Button text="Create New WBs" className="btn btn-order" />
                </Link>
                <Link to="/weighbridge-docket-tablet-export-pending">
                  <Button text="Pending export WBs" className="btn btn-prod" />
                </Link>
                <Button
                  text="Back"
                  className="btn btn-mat"
                  onClick={() => setCurrentOrder("")}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
};

export default WeighbridgeDocketTabletExport;

import React from "react";
import { useContext } from "react";
import SupplierContext from "../context/supplier/supplierContext";
import Spinner from "./Spinner";

const SupplierList = () => {
  const supplierContext = useContext(SupplierContext);

  const { setSupplier, loading } = supplierContext;

  const suppliers = JSON.parse(localStorage.getItem("suppliers"));

  if (loading === true || !suppliers) {
    return <Spinner />;
  }

  return (
    <div className="supplier-grid" style={gridStyle}>
      {suppliers.map(supplier => (
        <button
          className="grid-item"
          key={supplier.id}
          style={gridItemStyle}
          onClick={() => setSupplier(supplier.id, supplier.name)}
        >
          {supplier.name}
        </button>
      ))}
    </div>
  );
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  margin: "70px auto 0",
};

const gridItemStyle = {
  height: "81px",
  border: "1px solid #fff",
  lineHeight: "75px",
};

export default SupplierList;

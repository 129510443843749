import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import OrdersContext from '../context/orders/ordersContext';
import Button from '../component/Button';

const Orders = props => {
  const ordersContext = useContext(OrdersContext);

  const { orders, setCurrentOrder, getOrders } = ordersContext;

  useEffect(() => {
    getOrders();
    return () => {};
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="container">
        <div className="orders">
          <div>
            <>
              <Button text="Orders" className="btn btn-order" />
            </>

            <div className="orderslist">
              <Button text="Pending Orders" className="btn btn-order" />
              {orders && (
                <div className="list">
                  {orders.map(order => (
                    <div
                      className="list-item"
                      key={order.order_no}
                      onClick={() => {
                        setCurrentOrder(order);
                        props.history.push('/orders/details');
                      }}
                    >
                      {order.order_no}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <div>
            <Link to="/">
              <Button text="Back" className="btn btn-order" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Orders;

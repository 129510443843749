import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../component/Button";
import SupplierSelector from "../component/SupplierSelector";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Alert from "../component/Alert";
import Camera from "../component/Camera";
import TypeSelector from "../component/TypeSelector";
import GradeSelector from "../component/GradeSelector";
import { QrReader } from "react-qr-reader";
import HubSelector from "../component/HubSelector";
import ToggleSwitch from "../component/ToggleSwitch";

const BookInScan = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const { currentImages, setCurrentImages } = imagesContext;
  const {
    type,
    grade,
    generateMaterial,
    updateBookIn,
    currentWeighbridgeDocket,
    reelHub,
  } = materialContext;

  const { selectedSupplier } = supplierContext;

  // book in material data input
  const [materialData, setMaterialData] = useState({
    thickness: "",
    width: "",
    weight: "",
    rego: "", //may be removed, here also supplier book-in and in State functions
    materialFrom: "",
    diameter: "",
    isWeightEst: false,
    remark: "",
  });

  const toggleIsWeightEst = () => {
    setMaterialData({ ...materialData, isWeightEst: !isWeightEst });
  };

  const {
    thickness,
    width,
    weight,
    materialFrom,
    diameter,
    isWeightEst,
    remark,
  } = materialData;

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  const [selfUseBookIn, setSelfUseBookIn] = useState("");

  const previewStyle = {
    height: 240,
    width: 320,
  };

  const [scanner, setScanner] = useState({
    on: false,
    delay: 200,
    result: "No result",
  });

  // console.log(scanner);

  const handleScan = (result, error) => {
    if (!!result) {
      // console.log(JSON.parse(result));
      setSelfUseBookIn(JSON.parse(result));
      setScanner({ ...scanner, result: result, on: false });
    }

    if (!!error) {
      // console.info(error);
    }
  };
  // const handleScan = data => {
  //   if (data !== null) {
  //     setSelfUseBookIn(JSON.parse(data));
  //     // console.log(data);
  //     setScanner({ ...scanner, result: data, on: false });
  //   }
  // };

  // const handleError = err => {
  //   console.error(err);
  // };

  // create book in number
  const updateBookinSubmit = async () => {
    updateBookIn(
      selfUseBookIn.name,
      selectedSupplier.id,
      type,
      grade,
      thickness,
      width,
      weight,
      isWeightEst,
      materialFrom,
      diameter,
      remark,
      reelHub,
      currentImages,
      () => {
        generateMaterial(
          selectedSupplier.id,
          selfUseBookIn.id,
          currentWeighbridgeDocket.id,
          props
        );
        setCurrentImages([]);
      }
    );
  };

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center flex-column">
            {!selfUseBookIn ? (
              <>
                <Button text="Scan to proceed" className="btn btn-mat" />
                {scanner.on ? (
                  <div>
                    <QrReader
                      delay={scanner.delay}
                      style={previewStyle}
                      // onError={handleError}
                      // onScan={handleScan}
                      onResult={handleScan}
                      constraints={{
                        facingMode: "environment",
                      }}
                      className={"qr-scanner"}
                    />
                  </div>
                ) : (
                  <Button
                    text="Scan"
                    className="btn btn-mat"
                    onClick={() => setScanner({ ...scanner, on: true })}
                  />
                )}
              </>
            ) : (
              <div>
                <SupplierSelector proc="btn-mat" />
                {!selectedSupplier ? null : (
                  <>
                    <div>
                      <Button
                        text={selfUseBookIn.name}
                        className="btn btn-mat"
                      />
                      <HubSelector proc="mat" />
                      <TypeSelector proc="mat" />
                      <GradeSelector proc="mat" />
                    </div>
                    <div>
                      <Camera proc="mat" />
                      <div className="input-group">
                        <span className="input-fix-left">From</span>
                        <input
                          className="input-fix"
                          type="text"
                          name="materialFrom"
                          placeholder="e.g. Royhill"
                          value={materialFrom}
                          onChange={onChange}
                        />
                        <span className="input-fix-right"></span>
                      </div>
                      <div className="input-group">
                        <span className="input-fix-left">Thickness</span>
                        <input
                          className="input-fix"
                          type="number"
                          name="thickness"
                          value={thickness}
                          onChange={onChange}
                        />
                        <span className="input-fix-right">mm</span>
                      </div>
                      <div>
                        <div className="input-group">
                          <span className="input-fix-left">Width</span>
                          <input
                            className="input-fix"
                            type="number"
                            name="width"
                            value={width}
                            onChange={onChange}
                          />
                          <span className="input-fix-right">mm</span>
                        </div>
                      </div>
                      {type.name === "steel" ? (
                        <div className="input-group">
                          <span className="input-fix-left">Diameter</span>
                          <input
                            className="input-fix"
                            type="number"
                            name="diameter"
                            placeholder="Steel Diameter"
                            value={diameter ? diameter : ""}
                            onChange={onChange}
                          />
                          <span className="input-fix-right">mm</span>
                        </div>
                      ) : null}
                      <div className="input-group">
                        <span className="input-fix-left">Weight</span>
                        <input
                          className="input-fix"
                          type="number"
                          name="weight"
                          value={weight}
                          onChange={onChange}
                        />
                        <span className="input-fix-right">t</span>
                      </div>
                      <div className="toggle-switch">
                        <ToggleSwitch
                          name="isWeighEst"
                          value={isWeightEst}
                          onChange={toggleIsWeightEst}
                        />
                        <span>Weight estimated</span>
                      </div>
                      <div className="input-group">
                        <input
                          className="input-text"
                          type="text"
                          name="remark"
                          placeholder="Remarks"
                          value={remark}
                          onChange={onChange}
                        />
                      </div>
                      {currentImages.length > 0 &&
                      selectedSupplier &&
                      type &&
                      grade ? (
                        <Button
                          text="Create Book-In"
                          className="btn btn-mat"
                          onClick={() => {
                            updateBookinSubmit(selfUseBookIn.name);
                            // await props.history.push('/');
                          }}
                        />
                      ) : (
                        <Link to="/material">
                          <Button
                            text="Create Book-In"
                            className="btn btn-mat btn-disabled"
                          />
                        </Link>
                      )}
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div className="text-center">
            <Link to="/book-in-menu">
              <Button text="Back" className="btn btn-mat" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookInScan;

import React, { useContext, useEffect } from "react";
import Button from "../component/Button";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import SupplierSelector from "../component/SupplierSelector";

const MaterialPending = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);

  const { pendingMaterial, loading, getPendingMaterial, getMaterial } =
    materialContext;
  const { selectedSupplier } = supplierContext;

  useEffect(() => {
    selectedSupplier && getPendingMaterial(selectedSupplier.id);
    return () => {
      // second;
    };
    // eslint-disable-next-line
  }, [selectedSupplier]);

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          <div className="text-center">
            <SupplierSelector proc="btn-mat" />
            {selectedSupplier && (
              <>
                <Button text="Pending Material" className="btn btn-mat" />
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="list">
                    {pendingMaterial.map(material => (
                      <div
                        key={material.name}
                        className="list-item"
                        onClick={() => {
                          getMaterial(selectedSupplier.id, material.id, () => {
                            props.history.push("/material/update");
                          });
                        }}
                      >
                        {material.name}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="text-center">
            <Button
              text="Back"
              className="btn btn-mat"
              onClick={() => props.history.goBack()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MaterialPending;

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Button from "../component/Button";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import Camera from "../component/Camera";
import TypeSelector from "../component/TypeSelector";

const SupplierBookIn = props => {
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const { bookInName } = useParams();
  const {
    currentBookIn,
    type,
    grade,
    supplierUpdateBookIn,
    loading,
    getSupplierBookIn,
  } = materialContext;

  useEffect(() => {
    getSupplierBookIn(bookInName);
    // eslint-disable-next-line
  }, []);

  const { currentImages } = imagesContext;

  // book in material data input
  const [materialData, setMaterialData] = useState({
    thickness: "",
    width: "",
    rego: "",
    materialFrom: "",
    remark: "",
  });

  const { materialFrom, thickness, width, rego, remark } = materialData;

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  // submit book in by QR code
  const createBookinSubmit = async () => {
    await supplierUpdateBookIn(
      currentBookIn.name,
      type,
      grade,
      materialFrom,
      thickness,
      width,
      rego,
      remark,
      currentImages,
      props
    );
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading || !currentBookIn ? ( //very important to me @22/DEC/2021
          <Spinner />
        ) : (
          <div className="bookin">
            {!currentBookIn.is_confirmed ? (
              <div className="spreader">
                <div>
                  <Button text={bookInName} className="btn btn-mat" />
                </div>
                <div style={{ width: "240px" }}>
                  {!currentImages.length && (
                    <p>
                      Please take a photo of rego/trailer no. as well as belt
                      with book-in label sticked on
                    </p>
                  )}
                  <Camera proc="mat" />
                  <hr />
                  <p style={{ padding: "5px 0" }}>Optional information</p>
                  <TypeSelector proc="mat" />
                  <div className="input-group">
                    <span className="input-fix-left">From</span>
                    <input
                      className="input-fix"
                      type="text"
                      name="materialFrom"
                      placeholder="e.g. Royhill"
                      value={materialFrom}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <span className="input-fix-right"></span>
                  </div>
                  {/* <div className="input-group">
                    <span className="input-fix-left">Thickness</span>
                    <input
                      className="input-fix"
                      type="number"
                      name="thickness"
                      value={thickness}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <span className="input-fix-right">mm</span>
                  </div>

                  <div className="input-group">
                    <span className="input-fix-left">Width</span>
                    <input
                      className="input-fix"
                      type="number"
                      name="width"
                      value={width}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <span className="input-fix-right">mm</span>
                  </div> */}
                  <div className="input-group">
                    <span className="input-fix-left">Trailer ID</span>
                    <input
                      className="input-fix"
                      type="text"
                      name="rego"
                      value={rego}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <span className="input-fix-right"></span>
                  </div>
                  <div className="input-group">
                    <span className="input-fix-left">Remarks</span>
                    <input
                      className="input-fix"
                      type="textarea"
                      name="remark"
                      value={remark}
                      onChange={onChange}
                      autoComplete="off"
                    />
                    <span className="input-fix-right"></span>
                  </div>

                  {currentImages.length > 0 ? (
                    <Button
                      text="Create Book-In"
                      className="btn btn-mat"
                      onClick={async () => {
                        await createBookinSubmit();
                        // await props.history.push(
                        //   `/supplierbookin/completed/${bookInName}`
                        // );
                      }}
                    />
                  ) : (
                    <Button
                      text="Create Book-In"
                      className="btn btn-mat btn-disabled"
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="spreader">
                <Button text={bookInName} className="btn btn-mat" />
                <p>Book-In number already used, please scan another one</p>
                <div></div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SupplierBookIn;

import React, { useContext } from "react";
import Button from "../component/Button";
import ImagesContext from "../context/images/imagesContext";
import Alert from "../component/Alert";
// import Spinner from "../component/Spinner";
import OrdersContext from "../context/orders/ordersContext";

const WeighbridgeDocketTabletPrint = props => {
  const imagesContext = useContext(ImagesContext);
  const orderContext = useContext(OrdersContext);

  const {
    currentOrder,
    currentContainer,
    currentWeighbridgeDocket,
    newWBData,
    // loading,
    setCurrentContainer,
    setCurrentOrder,
    setCurrentWeighbridgeDocket,
  } = orderContext;

  // console.log(newWBData);

  const datetime = new Date();
  // console.log(datetime);
  const date = datetime.toLocaleDateString("en-AU");
  const time = datetime.toLocaleTimeString("en-AU");

  const etd = new Date(currentOrder.estimated_time).toLocaleDateString("en-AU");

  const { imageString } = imagesContext;

  const handlePrint = () => {
    // let printContents = document.getElementById("App").innerHTML;
    // let originalContents = document.body.innerHTML;
    // document.body.innerHTML = printContents;
    window.print();
    // document.body.innerHTML = originalContents;
  };

  // returned wb data
  // {
  //   "success": true,
  //   "docket": {
  //     "name": "WBE0057",
  //     "id": 49,
  //     "container_id": 21,
  //     "seal_number": "8887777",
  //     "from_site": null,
  //     "to": "345",
  //     "registration_no": "345",
  //     "cont_tare": "4.2",
  //     "first_gross": "345.0",
  //     "first_tare": "345.0",
  //     "nett": "0.0",
  //     "total_nett": "-4.2",
  //     "comment": "345",
  //     "weighed_by": "345",
  //     "driver_name": "345",
  //     "transport_company": "345",
  //     "signed": false,
  //     "signature_image": ""
  //   }
  // }

  return (
    <>
      <Alert />
      <div className="container-t" id="printablediv">
        {/* // weighbridge docket details */}

        <>
          <div className="wb-d-title">
            <h1>WEIGHBRIDGE TICKET</h1>
            <h3>
              No.
              {newWBData.name}
              {/* {currentOrder.supplier_name} */}
            </h3>
          </div>
          <hr />
          <div className="wb-d">
            <div className="wb-d-l">
              <div className="wb-d-list">
                <div>
                  From: {newWBData.from} | Site: {newWBData.from_site}
                </div>
                <div>To: {currentOrder.to}</div>
                <div>ETD: {etd}</div>
                <div className="grid-col-2">Date: {date}</div>
                <div className="grid-col-2">Time: {time}</div>
              </div>
              <hr />
              <div className="wb-d-list">
                {/* <h3>Order No: {currentOrder.order_no}</h3> */}
                <h3>CONTAINER</h3>
                <div>Container No.: {currentContainer.container_no}</div>
                <div>Seal number: {currentContainer.seal}</div>
                <div className="grid-col-2">
                  Cont Tare (Tn): {newWBData.cont_tare}
                </div>
              </div>
              <hr />
              <div className="wb-d-list">
                <h3>WEIGHBRDIGE</h3>
                <div className="grid-col-2">
                  <span className="input-label">
                    First Gross (Tn): {newWBData.first_gross}
                  </span>
                </div>
                <div className="grid-col-2">
                  <span className="input-label">
                    First Tare (Tn): {newWBData.first_tare}
                  </span>
                </div>
                <div className="grid-col-2">
                  <span className="input-label">
                    Nett (Tn): {newWBData.nett}
                  </span>
                </div>
                <div className="grid-col-2">
                  <span className="input-label">
                    Total Nett (Tn): {newWBData.total_nett}
                  </span>
                </div>
              </div>
            </div>
            <div className="wb-d-r">
              {/* <div className="wb-d-gallery"></div> */}
              <div className="wb-d-list">
                <div className="px-10">
                  <h3>TRANSPORT</h3>
                  <div className="flex-sp-btwn">
                    <span className="input-label">
                      Registration/Trailer ID: {newWBData.registration_no}
                    </span>
                  </div>
                  <div className="flex-sp-btwn">
                    <span className="input-label">
                      Transport Company: {newWBData.transport_company}
                    </span>
                  </div>
                  <div className="flex-sp-btwn">
                    <span className="input-label">
                      Driver's Name: {newWBData.driver_name}
                    </span>
                  </div>
                  <div className="flex-sp-btwn">
                    <span className="input-label">
                      REMARKS: {newWBData.comment}
                    </span>
                  </div>
                </div>
              </div>
              <div className="wb-d-signature">
                <div className="px-10">
                  <hr />
                  <div className="flex-sp-btwn py-5">
                    <span className="input-label">Signature:</span>
                  </div>
                </div>
                {currentWeighbridgeDocket.signature_image ? (
                  <img
                    width="500"
                    height="200"
                    src={currentWeighbridgeDocket.signature_image}
                    alt="signature"
                  />
                ) : (
                  <img
                    width="500"
                    height="200"
                    src={`${imageString}`}
                    alt="signature"
                  />
                )}
              </div>
            </div>
          </div>
          <hr />
          <div className="wb-d-nav">
            <Button
              text="Back to menu"
              className="btn btn-prod"
              onClick={() => {
                props.history.push(`/weighbridge-docket-tablet-main`);
                setCurrentContainer("");
                setCurrentOrder("");
                setCurrentWeighbridgeDocket("");
              }}
            />
            <Button
              text="Print"
              className="btn btn-mat"
              onClick={() => handlePrint()}
            />
          </div>
        </>
      </div>
    </>
  );
};

export default WeighbridgeDocketTabletPrint;

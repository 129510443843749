// To be deprecated

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Confirmation from "../component/Confirmation";
import Modal from "../component/Modal";

const Photo = ({ location }) => {
  const history = useHistory();
  const image = location.image.image;
  const { name, localUrl } = image;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="photo-container">
      <div></div>
      <img src={localUrl} alt={name} />
      <div className="photo-nav">
        <button className="btn-sq" onClick={() => history.goBack()}>
          <i className="fa-solid fa-arrow-left"></i>
        </button>
        <button className="btn-sq bg-danger" onClick={handleOpen}>
          <i className="fa-solid fa-trash-can"></i>
        </button>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        component={
          <Confirmation
            msg="Do you want to remove this photo?"
            onConfirm={() => {
              location.image.deleteImage(image);
              history.goBack();
            }}
            // location.image.deleteImage(image)
          />
        }
      />
    </div>
  );
};

export default Photo;

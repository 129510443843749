import React, { useContext, useState } from 'react';
import OrdersContext from '../context/orders/ordersContext';
import ImagesContext from '../context/images/imagesContext';
import Button from '../component/Button';
import Modal from '../component/Modal';
import Confirmation from '../component/Confirmation';
import Camera from '../component/Camera';

const OrderContainerSeal = props => {
  const ordersContext = useContext(OrdersContext);
  const imagesContext = useContext(ImagesContext)

  const { sealContainer } = ordersContext;
  const { currentImages } = imagesContext;

  const currentOrder = JSON.parse(localStorage.getItem('currentOrder'));
  const currentContainer = JSON.parse(localStorage.getItem('currentContainer'));

  // submit seal container
  const containerSealSubmit = async () => {
    await sealContainer(currentOrder.order_no, currentContainer.id, sealNumber, currentImages)
    await props.history.push('/orders/details')
  };

  //handle container seal number input
  const [sealNumber, setSealNumber] = useState('');

  const onChange = e =>
    setSealNumber(e.target.value);

  // handle modal open/close
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="container">
      <div className="orders">
        <div>
          <Button
            text={currentContainer.container_no}
            className="btn btn-order"
          />
          <div className="input-group">
            <input
              className="input-text"
              type="text"
              name="sealNumber"
              placeholder="Seal Number"
              value={sealNumber}
              onChange={onChange}
              autoComplete="off"
            />

            <p>Upload sealed container pictures</p>
            <Camera proc="order" />

          </div>



        </div>
        <div>
          {currentImages.length && sealNumber !== '' ?

            <Button
              text="Seal container"
              className="btn btn-order"
              onClick={handleOpen}
            /> : ''
          }

          <Button
            text="Back"
            className="btn btn-order"
            onClick={() => {
              props.history.push('/orders/container');
            }}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        component={
          <Confirmation
            msg={`Submitting container ${currentContainer.container_no} as sealed, Do you wish to proceed?`}
            onConfirm={() =>
              containerSealSubmit()
            }
            onCancel={() => {
              handleClose();
            }}
          />
        }
      />
    </div>
  );
};

export default OrderContainerSeal;

import {
  GET_SUPPLIERS,
  SET_LOADING,
  SET_SUPPLIER,
  UNSET_SUPPLIER,
} from '../types';

const SupplierReducer = (state, action) => {
  switch (action.type) {
    case GET_SUPPLIERS:
      localStorage.setItem('suppliers', JSON.stringify(action.payload));
      return {
        ...state,
        suppliers: action.payload,
        loading: false,
      };
    case SET_SUPPLIER:
      localStorage.setItem('selectedSupplier', JSON.stringify(action.payload));
      return {
        ...state,
        selectedSupplier: action.payload,
      };
    case UNSET_SUPPLIER:
      return {
        ...state,
        selectedSupplier: null,
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default SupplierReducer;

import React, { useContext, useState } from 'react';
import Button from '../component/Button';
import ProductionContext from '../context/production/productionContext';
import ImagesContext from '../context/images/imagesContext';
import Camera from '../component/Camera';
import Alert from '../component/Alert';
import AlertContext from '../context/alert/alertContext';
import Spinner from '../component/Spinner';
import Confirmation from '../component/Confirmation';
import Modal from '../component/Modal';

const ProductionWeigh = props => {
  const productionContext = useContext(ProductionContext);
  const imagesContext = useContext(ImagesContext);
  const alertContext = useContext(AlertContext);

  const { productionWeigh, loading } = productionContext;
  const { currentImages, setCurrentImages } = imagesContext;
  const { setAlert } = alertContext;

  const currentProduct = JSON.parse(localStorage.getItem('currentProduct'));

  const { name, material_id, id, thickness, width } = currentProduct;

  // setCurrentImages(process_records.images);

  const [weight, setWeight] = useState('');

  const onChange = e => setWeight(e.target.value);

  const submitWeight = async () => {
    if (weight !== '') {
      const res = await productionWeigh(
        material_id,
        id,
        thickness,
        width,
        weight,
        currentImages
      );
      res && setCurrentImages([]);
    } else {
      setAlert(` Please fill in measurement data`, 'warning');
    }
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <Button text={name} className="btn btn-prod" />
              <Camera setCurrentImages={setCurrentImages} proc="prod" />
            </div>
            <div style={{ width: '200px', margin: '0 auto' }}>
              <div className="input-group">
                <span className="input-fix-left">Weight</span>
                <input
                  className="input-fix"
                  type="number"
                  name="weight"
                  value={weight}
                  onChange={onChange}
                />
                <span className="input-fix-right">t</span>
              </div>
              {!currentImages || !weight ? (
                <Button
                  text="Need Data"
                  className="btn btn-prod btn-disabled"
                />
              ) : (
                <Button
                  text="Confirm"
                  className="btn btn-prod"
                  onClick={handleOpen}
                />
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              component={
                <Confirmation
                  msg="Product will be stocked in, do you wish to proceed?"
                  onConfirm={() => {
                    submitWeight();
                    props.history.push('/production/weighlist');
                  }}
                />
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ProductionWeigh;

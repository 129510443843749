import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Button from '../component/Button';
import SupplierSelector from '../component/SupplierSelector';
import SupplierContext from '../context/supplier/supplierContext';
import MaterialContext from '../context/material/materialContext';
import ImagesContext from '../context/images/imagesContext';
import AlertContext from '../context/alert/alertContext';
import Alert from '../component/Alert';
import Spinner from '../component/Spinner';
import Camera from '../component/Camera';
import TypeSelector from '../component/TypeSelector';
import GradeSelector from '../component/GradeSelector';

const BookIn = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const alertContext = useContext(AlertContext);

  const { currentImages } = imagesContext;
  const { setAlert } = alertContext;
  const { getPendingMaterial, type, grade, createBookIn, loading } =
    materialContext;

  const { selectedSupplier } = supplierContext;

  // book in material data input
  const [materialData, setMaterialData] = useState({
    thickness: '',
    width: '',
    weight: '',
  });

  const { thickness, width, weight } = materialData;

  const onChange = e =>
    setMaterialData({ ...materialData, [e.target.name]: e.target.value });

  // create book in number
  const createBookinSubmit = async () => {
    if (thickness !== '' && width !== '' && type !== '' && grade !== '') {
      await createBookIn(
        selectedSupplier.id,
        type,
        grade,
        thickness,
        width,
        weight,
        currentImages
      );
      await props.history.push('/');
    } else {
      setAlert(` Please choose/fill in data`, 'warning');
    }
  };

  useEffect(() => {
    if (selectedSupplier) getPendingMaterial(selectedSupplier.id);
    // eslint-disable-next-line
  }, [selectedSupplier]);

  return (
    <>
      <Alert />
      <div className="container">
        <div className="material">
          {loading ? (
            <Spinner />
          ) : (
            <div className="text-center">
              <SupplierSelector proc="btn-mat" />
              {!selectedSupplier ? null : (
                <div className="spreader">
                  <div>
                    <TypeSelector proc="mat" />
                    <GradeSelector proc="mat" />
                  </div>
                  <div>
                    <Camera proc="mat" />
                    <div className="input-group">
                      <span className="input-fix-left">Thickness</span>
                      <input
                        className="input-fix"
                        type="number"
                        name="thickness"
                        value={thickness}
                        onChange={onChange}
                      />
                      <span className="input-fix-right">mm</span>
                    </div>
                    <div>
                      <div className="input-group">
                        <span className="input-fix-left">Width</span>
                        <input
                          className="input-fix"
                          type="number"
                          name="width"
                          value={width}
                          onChange={onChange}
                        />
                        <span className="input-fix-right">mm</span>
                      </div>
                    </div>
                    <div className="input-group">
                      <span className="input-fix-left">Weight</span>
                      <input
                        className="input-fix"
                        type="number"
                        name="weight"
                        value={weight}
                        onChange={onChange}
                      />
                      <span className="input-fix-right">t</span>
                    </div>
                    {currentImages.length > 0 && selectedSupplier ? (
                      <Button
                        text="Create Book-In"
                        className="btn btn-mat"
                        onClick={() => {
                          createBookinSubmit();
                          // await props.history.push('/');
                        }}
                      />
                    ) : (
                      <Link to="/material">
                        <Button
                          text="Create Book-In"
                          className="btn btn-mat btn-disabled"
                        />
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          <div className="text-center">
            <Link to="/book-in-menu">
              <Button text="Back" className="btn btn-mat" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default BookIn;

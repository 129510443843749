import React, { useContext } from "react";
import Button from "../component/Button";
import { Link } from "react-router-dom";
import ProductionContext from "../context/production/productionContext";
import OrdersContext from "../context/orders/ordersContext";
import ImagesContext from "../context/images/imagesContext";
import Camera from "../component/Camera";

const OrderProduct = props => {
  const imagesContext = useContext(ImagesContext);
  const productionContext = useContext(ProductionContext);
  const ordersContext = useContext(OrdersContext);

  const { loadProduct } = ordersContext;
  const { currentProduct } = productionContext;
  const { currentImages } = imagesContext;

  const currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
  const currentContainer = JSON.parse(localStorage.getItem("currentContainer"));

  // // toggle create-container form show/hide
  // const [childVisible, setChildVisible] = useState(false);

  // const toggleCreateContainer = () => {
  //   setChildVisible(!childVisible);
  // };

  const LoadProductSubmit = async (product, processName) => {
    await loadProduct(
      currentOrder.order_no,
      currentContainer.id,
      product,
      currentImages,
      processName
    );
  };

  return (
    <>
      <div className="container">
        <div className="orders">
          <div>
            <Button text={currentProduct.name} className="btn btn-order" />
            <div className="load-product">
              <div>Weight: {currentProduct.weight}t</div>
              <div>Width: {currentProduct.width}mm</div>
              <div>Thickness: {currentProduct.thickness}mm</div>
              <hr />
              {!currentProduct.process_records.length ? (
                <div className="input-group">
                  <p>Take pre-load picture</p>
                  <Camera proc="order" />
                  {currentImages.length ? (
                    <Button
                      text="Upload"
                      className="btn btn-order"
                      onClick={async () => {
                        await LoadProductSubmit(currentProduct, "preload");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                <div className="input-group">
                  <Button text="Preload picture" className="btn btn-order" />
                  <div className="img-gallery">
                    {currentProduct.process_records[0].images.map(image => (
                      <div key={image.id} className="img-gallery-item">
                        <img src={image.url} alt={image.id} />
                      </div>
                    ))}
                  </div>
                  <hr />
                  <p>Take product picture after loading</p>
                  <Camera proc="order" />
                  {currentImages.length ? (
                    <Button
                      text="Upload"
                      className="btn btn-order"
                      onClick={async () => {
                        await LoadProductSubmit(currentProduct, "loaded");
                        await props.history.push("/orders/container");
                      }}
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <Link to="/orders/material">
              <Button text="Back" className="btn btn-order" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderProduct;

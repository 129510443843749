import React, { useContext, useState } from 'react';
import Alert from '../component/Alert';
import ProfileContext from '../context/profile/profileContext';
import Button from '../component/Button';

const ChangePassword = props => {
  const profileContext = useContext(ProfileContext);
  const { updatePassword } = profileContext;
  const [passwords, setPasswords] = useState({
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
  });
  const { oldPassword, newPassword, newPasswordRepeat } = passwords;

  const onChange = e =>
    setPasswords({ ...passwords, [e.target.name]: e.target.value });

  const updatePasswordSubmit = () => {
    updatePassword(oldPassword, newPassword, newPasswordRepeat, props);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        paddingTop: '100px',
      }}
    >
      <Alert />
      <div className="login-form text-center">
        <div className="form-control">
          <input
            className="input-text"
            name="oldPassword"
            type="password"
            placeholder="Old password"
            value={oldPassword}
            onChange={onChange}
          />
        </div>
        <div className="form-control">
          <input
            className="input-text"
            name="newPassword"
            type="password"
            placeholder="New password"
            value={newPassword}
            onChange={onChange}
          />
        </div>
        <div className="form-control">
          <input
            className="input-text"
            name="newPasswordRepeat"
            type="password"
            placeholder="Confirm new password"
            value={newPasswordRepeat}
            onChange={onChange}
          />
        </div>
        <Button
          className="btn btn-prod btn-login"
          text="Update"
          onClick={updatePasswordSubmit}
        />
      </div>
    </div>
  );
};

export default ChangePassword;

import React, { useContext, useState } from "react";
import ImagesContext from "../context/images/imagesContext";
import Modal from "./Modal";
import Confirmation from "./Confirmation";

const GalleryPhoto = props => {
  const imagesContext = useContext(ImagesContext);
  const { modalImage, deleteImage } = imagesContext;
  const { url } = modalImage;

  const { isEdit } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalPhotoStyle = {
    backgroundImage: `url(${url})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    height: "100%",
    backgroundPosition: "center"
  }


  return (
    <div className="photo-container">
      <div></div>
      <div id="modal-photo" style={modalPhotoStyle}></div>
      <div>
        {isEdit && (
          <div className="photo-nav">
            <button className="btn-sq" onClick={props.handleClose}>
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <button
              className="btn-sq bg-danger"
              onClick={() => {
                handleOpen();
              }}
            >
              <i className="fa-solid fa-trash-can"></i>
            </button>
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        component={
          <Confirmation
            msg="Do you want to remove this photo?"
            onConfirm={() => {
              deleteImage(modalImage);
              props.handleClose();
            }}
            onCancel={handleClose}
          // location.image.deleteImage(image)
          />
        }
      />
    </div>
  );
};

export default GalleryPhoto;

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "./Camera.css";
import ImagesContext from "../context/images/imagesContext";

const CameraMemo = props => {
  const imagesContext = useContext(ImagesContext);
  const { memoImages, setMemoImages, deleteMemoImage, resizeImageOnAttach } =
    imagesContext; //for images upload, an array of files
  const { proc } = props;

  // convert file array into images array for page display
  const setShowImageArr = fileArr => {
    const imagesArr = [];
    fileArr.forEach(file => {
      const image = {
        name: file.name,
        localUrl: URL.createObjectURL(file),
      };
      imagesArr.push(image);
    });
    return imagesArr;
  };
  const [imageGallery, setImages] = useState(setShowImageArr(memoImages)); //for showing images to be uploaded, an array of images with names and temp local url

  //handle adding images
  const handleImageChange = e => {
    console.log("memo");
    const fileArr = Object.values(e.target.files);
    if (!memoImages.length) {
      fileArr.forEach(async file => {
        resizeImageOnAttach(file).then(image => {
          memoImages.push(image);
          setMemoImages(memoImages);
          setImages(() => setShowImageArr(fileArr));
        });
      });
    } else {
      fileArr.forEach(async file => {
        const image = {
          name: file.name,
          // localUrl: URL.createObjectURL(file),
        };
        const found = memoImages.some(el => el.name === image.name);
        if (!found) {
          resizeImageOnAttach(file).then(image => {
            memoImages.push(image);
            setMemoImages(memoImages);
            setImages(() => setShowImageArr(memoImages));
          });
        }
      });
    }
    // console.log(memoImages);
  };

  return (
    <div className="camera-memo">
      <div className="img-gallery">
        {!imageGallery.length
          ? null
          : imageGallery.map(image => (
              <div key={image.name} className="img-gallery-item">
                <Link
                  to={{ pathname: "/photo", image: { image, deleteMemoImage } }}
                >
                  <img src={image.localUrl} alt={image.name} />
                </Link>
              </div>
            ))}
      </div>
      <div>
        <label>
          <input
            id="camInputMemo"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            multiple="multiple"
            onChange={handleImageChange}
          />
          <button
            className={`btn btn-${proc} btn-cam`}
            onClick={() => document.getElementById("camInputMemo").click()}
          >
            <i className="fa-solid fa-camera" />
          </button>
        </label>
      </div>
    </div>
  );
};

export default CameraMemo;

import React from "react";
import "./Modal.css";

const Modal = props => {
  if (!props.open) {
    return null;
  }

  const { proc } = props;

  return (
    <>
      <div className={`modal ${proc}`} onClick={props.onClose}>
        {props.component}
      </div>
    </>
  );
};

export default Modal;

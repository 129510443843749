import React, { useContext, useState } from 'react';
import Alert from '../component/Alert';
import Button from '../component/Button';
import MaterialContext from '../context/material/materialContext';
import ImagesContext from '../context/images/imagesContext';
import Spinner from '../component/Spinner';
import { Link } from 'react-router-dom';
import Camera from '../component/Camera';
import Modal from '../component/Modal';
import Confirmation from '../component/Confirmation';

const ProductionPreCut = props => {
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const { loading, autoCreateProduct, preCutMaterial } = materialContext;
  const { currentImages, setCurrentImages } = imagesContext;

  const currentMaterial = JSON.parse(localStorage.getItem('currentMaterial'));

  const directProductIn = async materialId => {
    await autoCreateProduct(materialId);
    props.history.push('/');
    console.log('create and stock in product directly');
  };

  const preCutSubmit = async () => {
    await preCutMaterial(currentMaterial.id, currentImages);
    setCurrentImages([]);
  };

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <div>
                <Button text={currentMaterial.name} className="btn btn-prod" />
              </div>
              <div>
                <Button
                  text="No cutting needed"
                  className="btn btn-prod"
                  onClick={handleOpen}
                />
              </div>
              <div className="process-images">
                <div>
                  <div>
                    <Button text="Pre-Cut Pictures" className="btn btn-prod" />
                  </div>
                  <Camera proc="prod" />
                </div>
                <div>
                  {!currentImages.length ? (
                    <Button text="Next" className="btn btn-prod btn-disabled" />
                  ) : (
                    <Button
                      text="Next"
                      className="btn btn-prod"
                      onClick={async () => {
                        await preCutSubmit();
                        props.history.push('/production/cut');
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="text-center">
              <Link to="/production/cutlist">
                <Button text="Back" className="btn btn-prod" />
              </Link>
            </div>
          </div>
        )}
        <Modal
          open={open}
          onClose={handleClose}
          component={
            <Confirmation
              msg={`${currentMaterial.name}-1 will be created as Product ID, Do you wish to proceed?`}
              onConfirm={async () => {
                await directProductIn(currentMaterial.id);
              }}
              onCancel={handleClose}
            />
          }
        />
      </div>
    </>
  );
};

export default ProductionPreCut;

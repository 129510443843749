import React, { useContext, useState, useEffect } from 'react';
import ProductionContext from '../context/production/productionContext';
import Alert from '../component/Alert';
import Spinner from '../component/Spinner';
import { Link } from 'react-router-dom';
import Button from '../component/Button';

const ProductionWeighList = props => {
  const productionContext = useContext(ProductionContext);

  const { pendingWeigh, getPendingWeigh, loading, setCurrentProduct } =
    productionContext;

  useEffect(() => {
    getPendingWeigh();
    // eslint-disable-next-line
  }, []);

  const [keyword, setKeyword] = useState('');

  const search = e => {
    setKeyword(e.target.value);
  };

  return (
    <>
      <Alert />
      <div className="container">
        {loading ? (
          <Spinner />
        ) : (
          <div className="production">
            <div className="text-center">
              <Button text="Pending Weigh" className="btn btn-prod" />
              {!pendingWeigh ? null : (
                <div>
                  <div className="form-control">
                    <input
                      className="input-text"
                      name="filter"
                      type="text"
                      placeholder="Filter products..."
                      value={keyword}
                      onChange={search}
                      autoComplete="off"
                    />
                  </div>
                  {!pendingWeigh.length ? null : (
                    <div>
                      <div className="list">
                        {pendingWeigh
                          .filter(
                            product =>
                              product.name
                                .toUpperCase()
                                .includes(keyword.toUpperCase()) ||
                              keyword === ''
                          )
                          .map(product => (
                            <div
                              key={product.name}
                              className="list-item"
                              onClick={async () => {
                                await setCurrentProduct(product);
                                props.history.push('/production/weigh');
                              }}
                            >
                              {product.name}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>

            <Link to="/production">
              <Button text="Back" className="btn btn-prod" />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductionWeighList;

import React, { useState, useContext, useEffect } from "react";
import Button from "../component/Button";
import logo from "../logo220.png";
import Alert from "../component/Alert";
import AlertContext from "../context/alert/alertContext";
import ProfileContext from "../context/profile/profileContext";

const Login = props => {
  const profileContext = useContext(ProfileContext);
  const alertContext = useContext(AlertContext);

  const { login, isAuthenticated, error, clearErrors, user } = profileContext;
  const { setAlert } = alertContext;

  useEffect(() => {
    if (isAuthenticated) {
      user.email === "tablet@actindustrial.com.au"
        ? props.history.push("/weighbridge-docket-tablet-main")
        : props.history.push("/");
    }

    if (error) {
      setAlert(error, "danger");
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error, isAuthenticated, props.history]);

  const [loginUser, setUser] = useState({
    username: localStorage.getItem("username")
      ? localStorage.getItem("username")
      : "",
    password: localStorage.getItem("password")
      ? localStorage.getItem("password")
      : "",
    rememberMe: localStorage.getItem("rememberMe") ? true : false,
  });

  const { username, password, rememberMe } = loginUser;

  const onChange = e =>
    setUser({ ...loginUser, [e.target.name]: e.target.value });
  const toggleRememberMe = () =>
    setUser({ ...loginUser, rememberMe: !rememberMe });

  const loginSubmit = async e => {
    e.preventDefault();

    if (username === "" || password === "") {
      alertContext.setAlert("Please fill in your credentials...", "danger");
    } else {
      login({ username, password, rememberMe });
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Alert />
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <form onSubmit={loginSubmit} className="login-form text-center">
        <div className="form-control">
          <input
            className="input-text"
            name="username"
            type="text"
            placeholder="username/email address"
            value={username}
            onChange={onChange}
          />
        </div>
        <div className="form-control">
          <input
            className="input-text"
            name="password"
            type="password"
            placeholder="password"
            value={password}
            onChange={onChange}
          />
        </div>
        <div className="form-control">
          <input
            className="input-checkbox"
            type="checkbox"
            name="rememberMe"
            checked={rememberMe}
            onChange={toggleRememberMe}
          />{" "}
          Remember me
        </div>
        <Button className="btn btn-prod btn-login" text="LOGIN" />
      </form>
    </div>
  );
};

export default Login;

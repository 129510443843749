import React, { useContext, useEffect } from "react";
import Button from "../component/Button";
import { Link } from "react-router-dom";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import TasksContext from "../context/tasks/tasksContext";
import Alert from "../component/Alert";

const Main = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const tasksContext = useContext(TasksContext);
  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    supplierContext.getSuppliers();
    supplierContext.unsetSupplier();
    tasksContext.getTasks("incompleted");
    materialContext.setCurrentMaterial("");
    materialContext.setType("");
    materialContext.setGrade("");
    imagesContext.setCurrentImages([]);
    if (!user) {
      localStorage.removeItem("token");
      props.history.push("/login");
    } else if (user.email === "tablet@actindustrial.com.au") {
      props.history.push("/weighbridge-docket-tablet-main");
    }

    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Alert />
      <div className="container">
        <div className="landing-buttons">
          <Link to="/weighbridge-docket">
            <Button
              className="btn btn-mat"
              text="Book-In"
              onClick={() => materialContext.setTo("book-in-menu")}
            />
          </Link>
          <Link to="/weighbridge-docket">
            <Button
              className="btn btn-mat"
              text="Receiving"
              onClick={() => materialContext.setTo("material")}
            />
          </Link>
          <Link to="/production">
            <Button className="btn btn-prod" text="Production" />
          </Link>
          <Link to="/orders">
            <Button className="btn btn-order" text="Orders" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Main;

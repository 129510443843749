import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import Login from "./pages/Login";
import { Header } from "./component/Header";
import Main from "./pages/Main";
import Orders from "./pages/Orders";
import Material from "./pages/Material";
import CreateMaterial from "./pages/CreateMaterial";
import UpdateMaterial from "./pages/UpdateMaterial";
import ConfirmMaterial from "./pages/ConfirmMaterial";
import Production from "./pages/Production";
import ProductionWeigh from "./pages/ProductionWeigh";
import Photo from "./pages/Photo";
import AlertState from "./context/alert/AlertState";
import ProfileState from "./context/profile/ProfileState";
import MemoState from "./context/memo/MemoState";
import TasksState from "./context/tasks/TasksState";
import setAuthToken from "./utils/setAuthToken";
import PrivateRoute from "./component/routing/PrivateRoute";
import SupplierState from "./context/supplier/SupplierState";
import MaterialState from "./context/material/MaterialState";
import ImagesState from "./context/images/imagesState";
import OrdersState from "./context/orders/OrdersState";
import OrderDetails from "./pages/OrderDetails";
import ProductionState from "./context/production/ProductionState";
import ProductionWeighList from "./pages/ProductionWeighList";
import ProductionCut from "./pages/ProductionCut";
import ProductionPreCut from "./pages/ProductionPreCut";
import ProductionCutList from "./pages/ProductionCutList";
import ProductDetails from "./pages/ProductDetails";
import Memo from "./pages/Memo";
import BookIn from "./pages/BookIn";
import BookInMenu from "./pages/BookInMenu";
import SupplierBookIn from "./pages/SupplierBookIn";
import SupplierBookInComplete from "./pages/SupplierBookInComplete";
import BookInDetails from "./pages/BookInDetails";
import OrderContainer from "./pages/OrderContainer";
import OrderAddProduct from "./pages/OrderAddProduct";
import OrderMaterial from "./pages/OrderMaterial";
import OrderProduct from "./pages/OrderProduct";
import OrderContainerSeal from "./pages/OrderContainerSeal";
import BookInScan from "./pages/BookInScan";
import ChangePassword from "./pages/ChangePassword";
import WeighbridgeDocket from "./pages/WeighbridgeDocket";
import WeighbridgeDocketCreate from "./pages/WeighbridgeDocketCreate";
import WeighbridgeDocketTablet from "./pages/WeighbridgeDocketTablet";
import MaterialPending from "./pages/MaterialPending";
import Tasks from "./pages/Tasks";
import TaskDetails from "./pages/TaskDetails";
import WeighbridgeDocketTabletMain from "./pages/WeighbridgeDocketTabletMain";
import WeighbridgeDocketTabletCreateExport from "./pages/WeighbridgeDocketTabletCreateExport";
import WeighbridgeDocketTabletPrint from "./pages/WeighbridgeDocketTabletPrint";
import WeighbridgeDocketTabletExportPending from "./pages/WeighbridgeDocketTabletExportPending";
import WeighbridgeDocketTabletExport from "./pages/WeighbridgeDocketTabletExport";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <AlertState>
      <ProfileState>
        <TasksState>
          <SupplierState>
            <ImagesState>
              <MaterialState>
                <ProductionState>
                  <OrdersState>
                    <MemoState>
                      <Router>
                        <Header />
                        <div className="App" id="App">
                          <Switch>
                            <Route exact path="/login" component={Login} />
                            <PrivateRoute
                              exact
                              path="/change-password"
                              component={ChangePassword}
                            />
                            <PrivateRoute
                              exact
                              path="/book-in-menu"
                              component={BookInMenu}
                            />
                            <PrivateRoute
                              exact
                              path="/book-in"
                              component={BookIn}
                            />
                            <PrivateRoute
                              exact
                              path="/book-in-scan"
                              component={BookInScan}
                            />
                            <Route
                              exact
                              path="/supplierbookin/:bookInName"
                              component={SupplierBookIn}
                            />
                            <Route
                              exact
                              path="/supplierbookin/completed/:bookInName"
                              component={SupplierBookInComplete}
                            />
                            <PrivateRoute
                              exact
                              path="/material/bookindetails"
                              component={BookInDetails}
                            />
                            <PrivateRoute exact path="/" component={Main} />
                            <PrivateRoute
                              exact
                              path="/material"
                              component={Material}
                            />
                            <PrivateRoute
                              exact
                              path="/weighbridge-docket"
                              component={WeighbridgeDocket}
                            />
                            <PrivateRoute
                              exact
                              path="/create-weighbridge-docket"
                              component={WeighbridgeDocketCreate}
                            />
                            <PrivateRoute
                              exact
                              path="/weighbridge-docket-tablet-export"
                              component={WeighbridgeDocketTabletExport}
                            />
                            <PrivateRoute
                              exact
                              path="/create-export-weighbridge-docket"
                              component={WeighbridgeDocketTabletCreateExport}
                            />
                            <PrivateRoute
                              exact
                              path="/create-export-weighbridge-docket/print"
                              component={WeighbridgeDocketTabletPrint}
                            />
                            <PrivateRoute
                              exact
                              path="/weighbridge-docket-tablet-main"
                              component={WeighbridgeDocketTabletMain}
                            />
                            <PrivateRoute
                              exact
                              path="/weighbridge-docket-tablet"
                              component={WeighbridgeDocketTablet}
                            />
                            <PrivateRoute
                              exact
                              path="/weighbridge-docket-tablet-export-pending"
                              component={WeighbridgeDocketTabletExportPending}
                            />
                            <PrivateRoute
                              exact
                              path="/material/create"
                              component={CreateMaterial}
                            />
                            <PrivateRoute
                              exact
                              path="/material/pending"
                              component={MaterialPending}
                            />
                            <PrivateRoute
                              exact
                              path="/material/update"
                              component={UpdateMaterial}
                            />
                            <PrivateRoute
                              exact
                              path="/material/confirm"
                              component={ConfirmMaterial}
                            />
                            <PrivateRoute
                              exact
                              path="/production"
                              component={Production}
                            />
                            <PrivateRoute
                              exact
                              path="/production/cut"
                              component={ProductionCut}
                            />
                            <PrivateRoute
                              exact
                              path="/production/product"
                              component={ProductDetails}
                            />
                            <PrivateRoute
                              exact
                              path="/production/precut"
                              component={ProductionPreCut}
                            />
                            <PrivateRoute
                              exact
                              path="/production/cutlist"
                              component={ProductionCutList}
                            />
                            <PrivateRoute
                              exact
                              path="/production/weigh"
                              component={ProductionWeigh}
                            />
                            <PrivateRoute
                              exact
                              path="/production/weighlist"
                              component={ProductionWeighList}
                            />
                            <PrivateRoute exact path="/memo" component={Memo} />
                            <PrivateRoute
                              exact
                              path="/orders"
                              component={Orders}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/details"
                              component={OrderDetails}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/container"
                              component={OrderContainer}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/container/seal"
                              component={OrderContainerSeal}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/addproducts"
                              component={OrderAddProduct}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/material"
                              component={OrderMaterial}
                            />
                            <PrivateRoute
                              exact
                              path="/orders/product"
                              component={OrderProduct}
                            />
                            <PrivateRoute
                              exact
                              path="/tasks/"
                              component={Tasks}
                            />
                            <PrivateRoute
                              exact
                              path="/tasks/taskdetails"
                              component={TaskDetails}
                            />
                            <Route exact path="/photo" component={Photo} />
                          </Switch>
                        </div>
                      </Router>
                    </MemoState>
                  </OrdersState>
                </ProductionState>
              </MaterialState>
            </ImagesState>
          </SupplierState>
        </TasksState>
      </ProfileState>
    </AlertState>
  );
}

export default App;

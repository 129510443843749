import React, { useContext, useState } from "react";
import Button from "../component/Button";
import Camera from "../component/Camera";
import TasksContext from "../context/tasks/tasksContext";
import { Document, Page, pdfjs } from "react-pdf";
import ImagesContext from "../context/images/imagesContext";

const TaskDetails = props => {
  const tasksContext = useContext(TasksContext);
  const imagesContext = useContext(ImagesContext);
  const { currentImages } = imagesContext;
  const { completeTask } = tasksContext;
  const myTask = JSON.parse(localStorage.getItem("currentTask"));

  // PDF variables and functions
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", e => {
    e.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  // inputs
  const [taskData, setTaskData] = useState({ comment: "", location: "" });

  const { comment, location } = taskData;

  const onChange = e =>
    setTaskData({ ...taskData, [e.target.name]: e.target.value });

  // complete task function
  const completeTaskSubmit = id => {
    completeTask(id, comment, currentImages, location, () =>
      props.history.push("/tasks")
    );
  };

  switch (myTask.category) {
    case "training":
      return (
        <>
          <div className="container">
            <div className="orders">
              <div>
                <>
                  <Button text="OHS Training" className="btn btn-prod" />
                  <p>{myTask.title}</p>
                </>

                <div className="task details">
                  <Camera proc="prod" />
                </div>
              </div>
              <div style={{ width: "200px", margin: "0 auto" }}>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="comment"
                    type="text"
                    placeholder="Remarks"
                    value={comment}
                    onChange={onChange}
                  />
                </div>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="location"
                    type="text"
                    placeholder="Location"
                    value={location}
                    onChange={onChange}
                  />
                </div>
                {currentImages && currentImages.length > 0 && (
                  <Button
                    text="Submit"
                    className="btn btn-prod"
                    onClick={() => completeTaskSubmit(myTask.id)}
                  />
                )}
                <Button
                  text="Back"
                  className="btn btn-prod"
                  onClick={() => props.history.goBack()}
                />
              </div>
            </div>
          </div>
        </>
      );
    case "machine":
      return (
        <>
          <div className="container">
            <div className="orders">
              <div>
                <>
                  <Button text="Maintenance" className="btn btn-prod" />
                  <p>{myTask.title}</p>
                </>

                <div className="task details">
                  <div className="image-gallery">
                    {!myTask.attachments.length
                      ? null
                      : myTask.attachments.map(attachment => (
                          <div
                            key={Object.keys(attachment)}
                            className="pdf-gallery-item"
                          >
                            <Document
                              file={attachment.attachment_url}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={pageNumber}
                                width={370}
                                renderAnnotationLayer={false}
                              />
                            </Document>
                            <div>
                              <div className="pagec">
                                Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                                {numPages || "--"}
                              </div>
                              <div className="buttonc">
                                <button
                                  type="button"
                                  disabled={pageNumber <= 1}
                                  onClick={previousPage}
                                  className="Pre"
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  disabled={pageNumber >= numPages}
                                  onClick={nextPage}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  <Camera proc="prod" />
                </div>
              </div>
              <div style={{ width: "200px", margin: "0 auto" }}>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="comment"
                    type="text"
                    placeholder="Remarks"
                    value={comment}
                    onChange={onChange}
                  />
                </div>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="location"
                    type="text"
                    placeholder="Location"
                    value={location}
                    onChange={onChange}
                  />
                </div>
                {currentImages && currentImages.length > 0 && (
                  <Button
                    text="Submit"
                    className="btn btn-prod"
                    onClick={() => completeTaskSubmit(myTask.id)}
                  />
                )}
                <Button
                  text="Back"
                  className="btn btn-prod"
                  onClick={() => props.history.goBack()}
                />
              </div>
            </div>
          </div>
        </>
      );
    case "workplace":
      return (
        <>
          <div className="container">
            <div className="orders">
              <div>
                <>
                  <Button text="Cleaning" className="btn btn-prod" />
                  <p>{myTask.title}</p>
                </>

                <div className="task details">
                  <div className="image-gallery">
                    {!myTask.attachments.length
                      ? null
                      : myTask.attachments.map(attachment => (
                          <div
                            key={Object.keys(attachment)}
                            className="pdf-gallery-item"
                          >
                            <Document
                              file={attachment.attachment_url}
                              onLoadSuccess={onDocumentLoadSuccess}
                            >
                              <Page
                                pageNumber={pageNumber}
                                width={370}
                                renderAnnotationLayer={false}
                              />
                            </Document>
                            <div>
                              <div className="pagec">
                                Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                                {numPages || "--"}
                              </div>
                              <div className="buttonc">
                                <button
                                  type="button"
                                  disabled={pageNumber <= 1}
                                  onClick={previousPage}
                                  className="Pre"
                                >
                                  Previous
                                </button>
                                <button
                                  type="button"
                                  disabled={pageNumber >= numPages}
                                  onClick={nextPage}
                                >
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                  <Camera proc="prod" />
                </div>
              </div>
              <div style={{ width: "200px", margin: "0 auto" }}>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="comment"
                    type="text"
                    placeholder="Remarks"
                    value={comment}
                    onChange={onChange}
                  />
                </div>
                <div className="form-control">
                  <input
                    className="input-text"
                    name="location"
                    type="text"
                    placeholder="Location"
                    value={location}
                    onChange={onChange}
                  />
                </div>
                {currentImages && currentImages.length > 0 && (
                  <Button
                    text="Submit"
                    className="btn btn-prod"
                    onClick={() => completeTaskSubmit(myTask.id)}
                  />
                )}
                <Button
                  text="Back"
                  className="btn btn-prod"
                  onClick={() => props.history.goBack()}
                />
              </div>
            </div>
          </div>
        </>
      );

    default:
      break;
  }

  // return (
  //
  // );
};

export default TaskDetails;

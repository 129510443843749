import {
  GET_ORDERS,
  SET_ORDER,
  SET_CONTAINER,
  GET_CONTAINERS,
  LOAD_PRODUCT,
  SET_NEW_WB_TO_PRINT,
  GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS,
  SET_WEIGHBRIDGE_DOCKET,
  UNLOAD_PRODUCT,
} from "../types";

const OrdersReducer = (state, action) => {
  switch (action.type) {
    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
      };
    case GET_CONTAINERS:
      const containers = action.payload;
      let readyContainers = [];
      if (containers.length > 0) {
        readyContainers = containers.filter(
          container => container.status === "sealed" && !container.wb_linked
        );
        // console.log(readyContainers);
      }
      return {
        ...state,
        containers: containers,
        readyContainers: readyContainers,
      };
    case SET_ORDER:
      localStorage.setItem("currentOrder", JSON.stringify(action.payload));
      return {
        ...state,
        currentOrder: action.payload,
      };
    case LOAD_PRODUCT:
      localStorage.setItem("loadedProducts", JSON.stringify(action.payload));
      return {
        ...state,
        loadedProducts: action.payload,
      };
    case UNLOAD_PRODUCT:
      const loaded = JSON.parse(localStorage.getItem("loadedProducts"));
      // console.log(loaded);
      const newLoaded = loaded.filter(
        product => product.name !== action.payload.name
      );
      // console.log(newLoaded);
      localStorage.setItem("loadedProducts", JSON.stringify(newLoaded));
      return {
        ...state,
        loadedProducts: newLoaded,
      };
    case SET_CONTAINER:
      localStorage.setItem("currentContainer", JSON.stringify(action.payload));
      localStorage.setItem(
        "loadedProducts",
        JSON.stringify(state.loadedProducts)
      );
      return {
        ...state,
        currentContainer: action.payload,
      };
    case SET_NEW_WB_TO_PRINT:
      return {
        ...state,
        newWBData: action.payload,
      };
    case GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS:
      return {
        ...state,
        pendingExportWBDockets: action.payload,
      };
    case SET_WEIGHBRIDGE_DOCKET:
      return {
        ...state,
        currentWeighbridgeDocket: action.payload,
      };
    default:
      return state;
  }
};

export default OrdersReducer;

import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ className, text, onClick, icon }) => {
  return (
    <button className={className} onClick={onClick}>
      {icon} {text}
    </button>
  );
};

Button.propTypes = {
  icon: PropTypes.object,
  className: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;

import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "../component/Button";
import SupplierContext from "../context/supplier/supplierContext";
import MaterialContext from "../context/material/materialContext";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import Modal from "../component/Modal";
import GalleryPhoto from "../component/GalleryPhoto";
import ImagesContext from "../context/images/imagesContext";

const WeighbridgeDocket = props => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  const {
    getPendingWeighbridgeDockets,
    pendingWeighbridgeDockets,
    setCurrentWeighbridgeDocket,
    loading,
  } = materialContext;

  const to = JSON.parse(localStorage.getItem("to"));

  const { setSupplier } = supplierContext;
  const { setModalImage } = imagesContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getPendingWeighbridgeDockets(); //get pending weighbridge dockets
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Alert />
      <div className="container-w">
        <div className="material">
          <div className="text-center">
            <Button text="Weighbridge dockets" className="btn btn-mat" />
            {!pendingWeighbridgeDockets.length ? null : (
              <>
                {loading ? (
                  <Spinner />
                ) : (
                  <div className="list-h">
                    {pendingWeighbridgeDockets.map(wbdocket => (
                      <div key={wbdocket.name} className="list-item-grid">
                        <div className="list-item-grid-gallery">
                          {wbdocket.images.map(image => (
                            <div
                              className="list-item-grid-image"
                              key={image.id}
                              onClick={() => {
                                setModalImage(image);
                                handleOpen();
                              }}
                            >
                              <img src={image.url} alt={image.id} />
                            </div>
                          ))}
                        </div>
                        <div
                          className="list-item-grid-desc"
                          onClick={() => {
                            setCurrentWeighbridgeDocket(wbdocket);
                            setSupplier(
                              wbdocket.supplier_id,
                              wbdocket.supplier_name
                            );
                            props.history.push(to);
                          }}
                        >
                          <div className="list-item-grid-name">
                            {wbdocket.name}
                          </div>
                          <div className="list-item-grid-remark">
                            From: {wbdocket.supplier_name}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}

            <Link to="/create-weighbridge-docket">
              <Button text="Create new" className="btn btn-mat" />
            </Link>
            <hr />
            <Button
              text="Pending material"
              className="btn btn-mat"
              onClick={() => props.history.push("/material/pending")}
            />
          </div>
          <div className="text-center">
            <Button
              text="Back"
              className="btn btn-mat"
              onClick={() => props.history.push("/")}
            />
          </div>
        </div>
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        onClose={handleClose}
        component={<GalleryPhoto isEdit={false} />}
      />
    </>
  );
};

export default WeighbridgeDocket;

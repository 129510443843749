import { GET_TASKS, GET_TASK, COMPLETE_TASK } from "../types";

const TasksReducer = (state, action) => {
  switch (action.type) {
    case GET_TASKS:
      localStorage.setItem("tasks", JSON.stringify(action.payload));
      return {
        ...state,
        tasks: action.payload,
      };
    case GET_TASK:
      localStorage.setItem("currentTask", JSON.stringify(action.payload));
      return {
        ...state,
        currentTask: action.payload,
      };
    case COMPLETE_TASK:
      localStorage.removeItem("currentTask");
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default TasksReducer;

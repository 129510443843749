import React, { useContext, useState } from "react";
import MaterialContext from "../context/material/materialContext";
import Modal from "../component/Modal";
import Button from "../component/Button";
import HubList from "./HubList";

export default function HubSelector({ proc }) {
  const materialContext = useContext(MaterialContext);

  const { reelHub, getReelHubList } = materialContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={selectorSytle}>
      {reelHub ? (
        <Button
          className={`btn btn-${proc}`}
          text={reelHub.name}
          onClick={async () => {
            await getReelHubList();
            handleOpen();
          }}
        />
      ) : (
        <Button
          className={`btn btn-${proc}`}
          text="Reel/Hub"
          onClick={async () => {
            await getReelHubList();
            handleOpen();
          }}
        />
      )}
      <Modal
        open={open}
        onClose={handleClose}
        component={<HubList />}
        proc="solid-bg-modal"
      />
    </div>
  );
}

const selectorSytle = {
  marginTop: "10px",
};

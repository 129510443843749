import React, { useReducer, useContext } from "react";
import ProductionReducer from "./productionReducer";
import ProductionContext from "./productionContext";
import AlertContext from "../alert/alertContext";
import axios from "axios";
import {
  SET_INIT_PRODUCTS,
  SET_CURRENT_PRODUCT,
  CONFIRM_PRODUCT,
  GET_PENDING_WEIGH,
  CREATE_NEW_PRODUCT,
  STOCK_IN_PRODUCT,
  SET_PROCESSING_PRODUCTS,
  BATCH_TO_WEIGH,
  SET_LOADING,
  TOGGLE_JOINT,
  STOCK_PRODUCTS,
} from "../types";

const ProductionState = props => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const initialState = {
    currentProduct: {},
    initProducts: [],
    procProducts: [],
    pendingWeigh: [],
    stockProducts: [],
    loading: false,
    error: null,
    joint: false,
  };

  const [state, dispatch] = useReducer(ProductionReducer, initialState);

  // toggle joint
  const toggleJoint = () =>
    dispatch({ type: TOGGLE_JOINT, payload: !state.joint });

  // get all pending weighing products
  const getPendingWeigh = async () => {
    setLoading();

    const endpoint = "api/v1/products/pending_weigh.json";

    const res = await axios({
      method: "GET",
      url: process.env.REACT_APP_DEV_API_URL + endpoint,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
    // console.log(res.data.products);

    dispatch({ type: GET_PENDING_WEIGH, payload: res.data.products });
  };

  // get all initialized products under one material
  const getInitProducts = async materialId => {
    setLoading();

    const endpoint = `api/v1/materials/${materialId}/products/initialized.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      dispatch({ type: SET_INIT_PRODUCTS, payload: res.data.products });
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // get all processing products under one material
  const getProcProducts = async materialId => {
    setLoading();

    const endpoint = `api/v1/materials/${materialId}/products/processing.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      dispatch({ type: SET_PROCESSING_PRODUCTS, payload: res.data.products });
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // setPendingProducts
  // const setPendingProducts = products => {
  //   dispatch({ type: SET_CURRENT_PRODUCT, payload: products });
  // };

  // setCurrentProduct
  const setCurrentProduct = product => {
    dispatch({ type: SET_CURRENT_PRODUCT, payload: product });
  };

  // create product - cutting
  const createProduct = async materialId => {
    setLoading();

    const endpoint = `api/v1/materials/${materialId}/products.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data.success) {
        console.log(res);
        setAlert("Server error", "danger");
      }
      dispatch({ type: CREATE_NEW_PRODUCT, payload: res.data.product });
      // console.log(res.data);
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // confirm product - finish cutting and push it into processing status
  const confirmProduct = async (
    materialId,
    productId,
    grade,
    type,
    thickness,
    width,
    diameter
  ) => {
    setLoading();

    var formData = new FormData();

    formData.append("product[category]", type);
    formData.append("product[grade]", grade);
    formData.append("product[thickness]", thickness);
    formData.append("product[width]", width);
    formData.append("product[diameter]", diameter);
    formData.append("process_record_name", "measure");

    const endpoint = `api/v1/materials/${materialId}/products/${productId}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      if (!res.data.success) {
        console.log(res);
        setAlert("Server error", "danger");
        return false;
      } else {
        // console.log(res.data);
        dispatch({ type: CONFIRM_PRODUCT });
        return true;
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
      return false;
    }
  };

  // checkout material from stock
  const batchToWeigh = async materialId => {
    setLoading();

    const endpoint = `api/v1/materials/${materialId}/producted.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data.success) {
        console.log(res);
        setAlert("Server error - producted", "danger");
      }
      dispatch({ type: BATCH_TO_WEIGH });
      // console.log(res);
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // delete product
  const deleteProduct = async (materialId, productId) => {
    // setLoading();
    const endpoint = `api/v1/materials/${materialId}/products/${productId}.json`;

    try {
      const res = await axios({
        method: "DELETE",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data.success) {
        // console.log(res);
        setAlert("Server error - deleting product", "danger");
      } else {
        // console.log(res);
        setAlert("Product Deleted", "success");
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      // console.log(err);
    }
  };

  // get stock products
  const getStockProducts = async materialId => {
    // setLoading();
    const endpoint = `api/v1/materials/${materialId}/products/completed.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data) {
        // console.log(res);
        setAlert("Server error - getting stock product", "danger");
      } else {
        // console.log(res);
        dispatch({ type: STOCK_PRODUCTS, payload: res.data.products });
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      // console.log(err);
    }
  };

  // update product - weighing
  const productionWeigh = async (
    materialId,
    productId,
    thickness,
    width,
    weight,
    currentImages
  ) => {
    setLoading();

    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("product[thickness]", thickness);
    formData.append("product[width]", width);
    formData.append("product[weight]", weight);
    formData.append("process_record_name", "weigh");

    const endpoint = `api/v1/materials/${materialId}/products/${productId}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      if (!res.data.success) {
        setAlert("Server error", "danger");
        console.log(res);
        return false;
      }

      setAlert("Product stocked in", "success");
      dispatch({ type: STOCK_IN_PRODUCT });
      getPendingWeigh();
      return true;
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
      return false;
    }
  };

  // show loading spinner
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <ProductionContext.Provider
      value={{
        currentProduct: state.currentProduct,
        loading: state.loading,
        error: state.error,
        pendingWeigh: state.pendingWeigh,
        initProducts: state.initProducts,
        procProducts: state.procProducts,
        stockProducts: state.stockProducts,
        joint: state.joint,
        setCurrentProduct,
        productionWeigh,
        getPendingWeigh,
        getInitProducts,
        getProcProducts,
        // setPendingProducts,
        toggleJoint,
        createProduct,
        confirmProduct,
        batchToWeigh,
        deleteProduct,
        getStockProducts,
      }}
    >
      {props.children}
    </ProductionContext.Provider>
  );
};

export default ProductionState;

import React, { useContext, useEffect, useState } from "react";
import Button from "../component/Button";
// import MaterialContext from "../context/material/materialContext";
import ImagesContext from "../context/images/imagesContext";
import Modal from "../component/Modal";
import GalleryPhoto from "../component/GalleryPhoto";
import Alert from "../component/Alert";
import Spinner from "../component/Spinner";
import Signature from "../component/Signature";
import OrdersContext from "../context/orders/ordersContext";

const WeighbridgeDocketTabletCreateExport = props => {
  // const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);
  const orderContext = useContext(OrdersContext);

  const {
    orders,
    setCurrentOrder,
    getOrders,
    currentOrder,
    getContainers,
    readyContainers,
    setCurrentContainer,
    currentContainer,
    createExportWeighbridgeDocket,
    loading,
  } = orderContext;

  // const {
  //   getPendingWeighbridgeDockets,
  //   setCurrentWeighbridgeDocket,
  //   // currentWeighbridgeDocket,
  //   loading,
  //   // setTo,
  // } = materialContext;

  const { setModalImage, imageString } = imagesContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // rego input
  const [wbdata, setWbData] = useState({
    rego: "",
    driverName: "",
    transportCompany: currentOrder.transport_company,
    firstGross: "",
    firstTare: "",
    remarks: "",
  });

  const onChange = e =>
    setWbData({ ...wbdata, [e.target.name]: e.target.value });

  const { rego, driverName, transportCompany, firstGross, firstTare, remarks } =
    wbdata;

  useEffect(() => {
    getOrders(); //get current orders
    setCurrentContainer();
    // eslint-disable-next-line
  }, []);

  const weighbridgeDocketSignSubmit = () => {
    createExportWeighbridgeDocket(
      currentContainer.id,
      "completed",
      firstGross,
      firstTare,
      rego,
      remarks,
      transportCompany,
      driverName,
      imageString,
      () => {
        // setCurrentContainer("");
        // setCurrentOrder("");
        props.history.push(`/create-export-weighbridge-docket/print`);
      }
    );
  };

  const weighbridgeDocketSaveDraft = () => {
    createExportWeighbridgeDocket(
      "",
      "pending",
      firstGross,
      firstTare,
      rego,
      remarks,
      transportCompany,
      driverName,
      imageString,
      () => {
        setCurrentContainer("");
        // setCurrentOrder("");
        props.history.push(`/weighbridge-docket-tablet-export`);
      }
    );
  };

  return (
    <>
      <Alert />
      <div className="container-t">
        <>
          {!currentOrder ? (
            <>
              <div className="wb-list-title">
                <h3>Select export order for this weighbridge docket:</h3>
              </div>
              {loading ? (
                <Spinner />
              ) : !orders.length ? (
                <div className="wb-list-content">
                  <h3>No current export orders</h3>
                </div>
              ) : (
                <>
                  {loading ? (
                    <Spinner />
                  ) : (
                    <>
                      <div className="wb-list">
                        {orders.map(order => (
                          <div
                            className="list-item text-center"
                            key={order.order_no}
                            onClick={() => {
                              setCurrentOrder(order);
                              getContainers(order.order_no);
                            }}
                          >
                            {order.order_no}
                          </div>
                        ))}
                      </div>
                      <div className="wb-d-nav">
                        <Button
                          text="Back"
                          className="btn btn-prod"
                          onClick={() =>
                            props.history.push(
                              `/weighbridge-docket-tablet-main`
                            )
                          }
                        />
                        <Button
                          text="Refresh"
                          className="btn btn-mat"
                          onClick={() => window.location.reload()}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          ) : (
            // weighbridge docket details
            <>
              <div className="wb-d-title">
                <h1>New Export Weighbridge Docket</h1>
                <h3>
                  For Order No.: {currentOrder.order_no}
                  {/* {currentOrder.supplier_name} */}
                </h3>
              </div>
              <hr />
              <div className="wb-d">
                <div className="wb-d-l">
                  {!currentContainer ? (
                    <div className="wb-d-list">
                      <div>
                        <h3>Select from completed containers:</h3>
                      </div>
                      {readyContainers.length > 0 ? (
                        <div>
                          {readyContainers.map(container => (
                            <div
                              key={container.id}
                              className="list-item text-center"
                              onClick={() => {
                                setCurrentContainer(container);
                              }}
                            >
                              Container No.: {container.container_no}
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="wb-d-item">
                          <p>
                            No completed container yet, please check with
                            workshop...
                          </p>
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <div className="wb-d-list">
                        <h3>Container No.: {currentContainer.container_no}</h3>
                        <div>
                          Tare Weight: {currentContainer.init_weight} Tn
                        </div>
                        <div>
                          Products Loaded Qty:{" "}
                          {currentContainer.total_products_count}
                        </div>
                        <div>
                          Total Products Weight:{" "}
                          {currentContainer.total_products_weight} Tn
                          <div>Seal number: {currentContainer.seal}</div>
                        </div>
                        <Button
                          text="Choose another"
                          className="btn btn-mat"
                          onClick={() => setCurrentContainer("")}
                        />
                      </div>
                    </>
                  )}
                  <hr />
                  <div className="wb-d-list">
                    <h3>Weighbridge data entry:</h3>
                    <div className="grid-col-2">
                      <span className="input-label">First Gross (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstGross"
                        value={firstGross}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">First Tare (Tn): </span>
                      <input
                        className="input-text"
                        type="number"
                        name="firstTare"
                        value={firstTare}
                        onChange={onChange}
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">
                        Registration/Trailer ID:{" "}
                      </span>
                      <input
                        className="input-text"
                        type="text"
                        name="rego"
                        value={rego}
                        onChange={onChange}
                        placeholder="Rego"
                      />
                    </div>
                    <div className="grid-col-2">
                      <span className="input-label">Remarks: </span>
                      <input
                        className="input-text"
                        type="text"
                        name="remarks"
                        value={remarks}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="wb-d-r">
                  <div className="wb-d-gallery">
                    {currentContainer ? (
                      currentContainer.process_records.map(record => (
                        <div
                          className="wb-list-item-grid-image"
                          key={record.name}
                          onClick={() => {
                            setModalImage(record.images[0]);
                            handleOpen();
                          }}
                        >
                          <img
                            src={record.images[0].url}
                            alt={record.images[0].id}
                          />
                          {record.name}
                        </div>
                      ))
                    ) : (
                      // currentContainer.process_records[1].images.map(image => (
                      //   <div
                      //     className="wb-list-item-grid-image"
                      //     key={image.id}
                      //     onClick={() => {
                      //       setModalImage(image);
                      //       handleOpen();
                      //     }}
                      //   >
                      //     <img src={image.url} alt={image.id} />
                      //   </div>
                      // ))
                      <></>
                    )}
                  </div>
                  <div className="wb-d-signature">
                    <hr />
                    <div className="px-10">
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Transport Company: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="transportCompany"
                          value={transportCompany}
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                      <div className="flex-sp-btwn py-5">
                        <span className="input-label">Driver's Name: </span>
                        <input
                          className="input-text"
                          type="text"
                          name="driverName"
                          value={driverName}
                          onChange={onChange}
                          style={{ marginBottom: "0" }}
                        />
                      </div>
                    </div>
                    {/* <Test penColor={"black"} /> */}
                    <Signature penColor={"black"} />
                  </div>
                </div>
              </div>
              <hr />
              <div className="wb-d-nav">
                <Button
                  text="Back"
                  className="btn btn-prod"
                  onClick={() => {
                    props.history.goBack();
                    setCurrentContainer("");
                  }}
                />
                {rego ? (
                  <Button
                    text="Save Draft"
                    className="btn btn-prod"
                    onClick={() => weighbridgeDocketSaveDraft()}
                  />
                ) : (
                  <Button
                    text="Save Draft"
                    className="btn btn-prod btn-disabled"
                  />
                )}
                {imageString &&
                currentOrder &&
                currentContainer &&
                transportCompany ? (
                  <Button
                    text="Submit"
                    className="btn btn-mat"
                    onClick={() => weighbridgeDocketSignSubmit()}
                  />
                ) : (
                  <Button text="Submit" className="btn btn-mat btn-disabled" />
                )}
              </div>
            </>
          )}
        </>
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        onClose={handleClose}
        component={<GalleryPhoto isEdit={false} />}
      />
    </>
  );
};

export default WeighbridgeDocketTabletCreateExport;

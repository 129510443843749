import React, { useContext } from "react";
import "./HeaderMenu.css";
import Button from "../component/Button";
import { Link } from "react-router-dom";
import ProfileContext from "../context/profile/profileContext";

const HeaderMenu = props => {
  const profileContext = useContext(ProfileContext);
  const { logout } = profileContext;

  const user = JSON.parse(localStorage.getItem("user"));

  const { email } = user;

  return (
    <div className="container">
      <div className="header-menu">
        <div className="text-center">User: {email}</div>
        <Link to="/change-password">
          <Button text="Change Password" className="btn btn-mat" />
        </Link>

        <Button text="Logout" className="btn btn-prod" onClick={logout} />
      </div>
    </div>
  );
};

export default HeaderMenu;

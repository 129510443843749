import React, { useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import Alert from '../component/Alert';
import Button from '../component/Button';
import SupplierContext from '../context/supplier/supplierContext';
import MaterialContext from '../context/material/materialContext';
import ImagesContext from '../context/images/imagesContext';

const Production = () => {
  const supplierContext = useContext(SupplierContext);
  const materialContext = useContext(MaterialContext);
  const imagesContext = useContext(ImagesContext);

  useEffect(() => {
    supplierContext.unsetSupplier();
    materialContext.setCurrentMaterial('');
    materialContext.setType('');
    materialContext.setGrade('');
    imagesContext.setCurrentImages([]);
    // productionContext.setPendingProducts([]);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Alert />
      <div className="container">
        <div className="production">
          <div style={{ marginTop: '20px' }}>
            <div style={{ marginTop: '20px' }}>
              <Link to="/production/cutlist">
                <Button text="Cut" className="btn btn-prod" />
              </Link>
            </div>
            <div style={{ marginTop: '20px' }}>
              <Link to="/production/weighlist">
                <Button text="Weigh" className="btn btn-prod" />
              </Link>
            </div>
          </div>
          <Link to="/">
            <Button text="Back" className="btn btn-prod" />
          </Link>
        </div>
      </div>
    </>
  );
};

export default Production;

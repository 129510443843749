import React, { useContext, useState } from "react";
import SupplierContext from "../context/supplier/supplierContext";
import Modal from "../component/Modal";
import Button from "../component/Button";
import SupplierList from "./SupplierList";
import PropTypes from "prop-types";

export default function SupplierSelector({ proc }) {
  const supplierContext = useContext(SupplierContext);

  const { selectedSupplier } = supplierContext;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div style={selectorSytle}>
      {selectedSupplier ? (
        <Button
          className={`btn ${proc}`}
          text={selectedSupplier.name}
          onClick={() => {
            handleOpen();
          }}
        />
      ) : (
        <Button
          className={`btn ${proc} btn-supplier-selector`}
          text="Supplier"
          onClick={handleOpen}
        />
      )}
      <Modal
        open={open}
        proc="fs-modal"
        onClose={handleClose}
        component={<SupplierList />}
      />
    </div>
  );
}

SupplierSelector.propTypes = {
  proc: PropTypes.string,
};

const selectorSytle = {
  marginTop: "20px",
};

import React, { useReducer, useContext } from "react";
import TasksReducer from "./tasksReducer";
import TasksContext from "./tasksContext";
import AlertContext from "../alert/alertContext";
import { GET_TASKS, GET_TASK, COMPLETE_TASK } from "../types";
import axios from "axios";

const TasksState = props => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;
  const initialState = {
    tasks: JSON.parse(localStorage.getItem("tasks")),
    currentTask: null,
  };

  const [state, dispatch] = useReducer(TasksReducer, initialState);

  // get tasks from server
  const getTasks = async type => {
    // axios
    //   .get(`https://api.dev.mrsbelts.com.au/api/v1/my_tasks.json`, {
    //     params: {
    //       type: type,
    //     },
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //     },
    //   })
    //   .then(res => {
    //     // console.log(res);
    //     dispatch({ type: GET_TASKS, payload: res.data.user_tasks });
    //   })
    //   .catch(err => console.log(err));

    const endpoint = `api/v1/my_tasks.json?type=${type}`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      dispatch({ type: GET_TASKS, payload: res.data.user_tasks });
    } catch (err) {
      console.log(err);
    }
  };

  const getTask = async (id, callback) => {
    const endpoint = `api/v1/my_tasks/${id}.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      console.log(res);
      dispatch({ type: GET_TASK, payload: res.data.user_task });
      callback();
    } catch (err) {
      console.log(err);
    }
  };

  const completeTask = async (id, comment, attachment, location, callback) => {
    var formData = new FormData();

    if (attachment.length > 0) {
      var i = attachment.length;

      for (var x = 0; x < i; x++) {
        formData.append("user_task[attachment][]", attachment[x]);
      }
    }

    formData.append("user_task[comment]", comment);
    formData.append("user_task[location]", location);

    const endpoint = `api/v1/my_tasks/${id}/complete.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      console.log(res);
      callback();
      if (!res.data) {
        // unsetLoading();
        setAlert("Server error", "danger");
      } else if (res.data.field_errors) {
        console.log(res);
        // unsetLoading();
        setAlert(res.data.field_errors[0].message, "danger");
      } else {
        // unsetLoading();
        // console.log(res);
      }
    } catch (err) {
      // unsetLoading();
      console.log(err);
      setAlert("Server error", "danger");
    }

    dispatch({ type: COMPLETE_TASK, payload: "" });
  };

  return (
    <TasksContext.Provider
      value={{
        tasks: state.tasks,
        currentTask: state.currentTask,
        getTasks,
        getTask,
        completeTask,
      }}
    >
      {props.children}
    </TasksContext.Provider>
  );
};

export default TasksState;

import React, { useContext, useReducer } from "react";
import OrdersReducer from "./ordersReducer";
import OrdersContext from "./ordersContext";
import axios from "axios";
import {
  GET_ORDERS,
  SET_LOADING,
  SET_ORDER,
  SET_CONTAINER,
  GET_CONTAINERS,
  UNSET_LOADING,
  LOAD_PRODUCT,
  UNLOAD_PRODUCT,
  GET_LOCAL_ORDERS,
  SET_NEW_WB_TO_PRINT,
  SET_WEIGHBRIDGE_DOCKET,
  GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS,
} from "../types";
import ImagesContext from "../images/imagesContext";
import ProductionContext from "../production/productionContext";
import AlertContext from "../alert/alertContext";

const OrdersState = props => {
  const initialState = {
    orders: [],
    currentOrder: null,
    currentContainer: null,
    containers: [],
    readyContainers: [],
    loadedProducts: [],
    preLoadedProducts: null,
    newWBData: null,
    loading: false,
    pendingExportWBDockets: [],
    currentWeighbridgeDocket: "",
  };

  const productionContext = useContext(ProductionContext);
  const alertContext = useContext(AlertContext);
  const imagesContext = useContext(ImagesContext);

  const { setCurrentProduct } = productionContext;
  const { setCurrentImages } = imagesContext;
  const { setAlert } = alertContext;

  const [state, dispatch] = useReducer(OrdersReducer, initialState);

  // get orders
  const getOrders = async () => {
    const endpoint = "api/v1/orders/processing.json";

    try {
      setLoading();
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (!res.data) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error - get orders", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        dispatch({ type: GET_ORDERS, payload: res.data.orders });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get orders
  const getLocalOrders = async () => {
    const endpoint = "api/v1/local_orders.json";

    try {
      setLoading();
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      // console.log(res);
      if (!res.data) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error - get orders", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        dispatch({ type: GET_LOCAL_ORDERS, payload: res.data.orders });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // update local order status
  const updateLocalOrders = async (
    orderNumber,
    status,
    currentImages,
    props,
    to
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }
    formData.append("process_record_name", status);

    const endpoint = `api/v1/local_orders/${orderNumber}.json`;

    try {
      setLoading();
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      console.log(res);
      if (!res.data.containers) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error - get containers", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        props.history.push(to);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getContainers = async orderNumber => {
    const endpoint = `api/v1/orders/${orderNumber}/containers.json`;

    try {
      setLoading();
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data.containers) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error - get containers", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        dispatch({ type: GET_CONTAINERS, payload: res.data.containers });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // get loaded products of certain container
  const getLoadedProducts = async (orderNumber, containerId) => {
    const endpoint = `api/v1/orders/${orderNumber}/containers/${containerId}/added_products.json`;

    try {
      setLoading();
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });
      if (!res.data.products) {
        unsetLoading();
        // console.log(res);
        setAlert("Server error - get containers", "danger");
      } else {
        unsetLoading();
        // console.log(res);
        dispatch({ type: LOAD_PRODUCT, payload: res.data.products });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const setCurrentOrder = order => {
    dispatch({ type: SET_ORDER, payload: order });
  };

  // create new containers
  const createContainer = async (
    orderNumber,
    containerNumber,
    weight,
    currentImages
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("container[container_no]", containerNumber);
    formData.append("container[init_weight]", weight);
    formData.append("process_record_name", "generate");

    const endpoint = `api/v1/orders/${orderNumber}/containers.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        // console.log(res);
        dispatch({ type: SET_CONTAINER, payload: res.data.container });
        setCurrentImages([]);
        setAlert("Container created, please add products", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // load product into container
  const loadProduct = async (
    orderNumber,
    containerId,
    product,
    currentImages,
    processName
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("product_id", product.id);
    formData.append("process_record_name", processName);

    const endpoint = `api/v1/orders/${orderNumber}/containers/${containerId}/add_product.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        // console.log(res.data.container_product);
        setCurrentProduct(res.data.container_product);
        setCurrentImages([]);
        setAlert("Picture uploaded, please proceed", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // unload product from container
  const unloadProduct = async (orderNumber, containerId, product) => {
    setLoading();
    var formData = new FormData();

    formData.append("product_id", product.id);

    const endpoint = `api/v1/orders/${orderNumber}/containers/${containerId}/revert_product.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        console.log(res);
        dispatch({ type: UNLOAD_PRODUCT, payload: product });
        setAlert("Picture uploaded, please proceed", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // Seal Container
  const sealContainer = async (
    orderNumber,
    containerId,
    sealNo,
    currentImages
  ) => {
    setLoading();
    var formData = new FormData();

    var i = currentImages.length;

    for (var x = 0; x < i; x++) {
      formData.append("process_record_images[]", currentImages[x]);
    }

    formData.append("container[seal]", sealNo);
    formData.append("process_record_name", "sealed");

    const endpoint = `api/v1/orders/${orderNumber}/containers/${containerId}.json`;

    try {
      const res = await axios({
        method: "PATCH",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        // console.log(res);
        setCurrentImages([]);
        setAlert("Container sealed", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // Complete order
  const completeOrder = async orderNumber => {
    setLoading();

    const endpoint = `api/v1/orders/${orderNumber}/complete.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        console.log(res);
        setAlert("Order completed", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // set current container
  const setCurrentContainer = container => {
    dispatch({ type: SET_CONTAINER, payload: container });
  };

  // create new export weighbridge docket
  const createExportWeighbridgeDocket = async (
    containerId,
    status,
    firstGross,
    firstTare,
    rego,
    remarks,
    transportCompany,
    driverName,
    signatureString,
    callback //do things when successfully created, eg. redirecting to menu page.
  ) => {
    setLoading();

    var formData = new FormData();

    formData.append("weighbridge_docket[container_id]", containerId);
    formData.append("weighbridge_docket[status]", status);
    formData.append("weighbridge_docket[first_gross]", firstGross);
    formData.append("weighbridge_docket[first_tare]", firstTare);
    formData.append("weighbridge_docket[registration_no]", rego);
    formData.append("weighbridge_docket[transport_company]", transportCompany);
    formData.append("weighbridge_docket[driver_name]", driverName);
    formData.append("weighbridge_docket[comment]", remarks);
    formData.append(
      "weighbridge_docket[signature_image][data]",
      signatureString
    );

    const endpoint = `api/v1/export_weighbridge_dockets.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });
      console.log(res);

      if (!res.data.success) {
        unsetLoading();
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        dispatch({ type: SET_NEW_WB_TO_PRINT, payload: res.data.docket });
        await callback();
        setAlert("New Export WB created", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };
  // update export weighbridge docket
  const updateExportWeighbridgeDocket = async (
    id,
    containerId,
    status,
    firstGross,
    firstTare,
    rego,
    remarks,
    transportCompany,
    driverName,
    signatureString,
    callback //do things when successfully created, eg. redirecting to menu page.
  ) => {
    setLoading();

    var formData = new FormData();

    formData.append("weighbridge_docket[container_id]", containerId);
    formData.append("weighbridge_docket[status]", status);
    formData.append("weighbridge_docket[first_gross]", firstGross);
    formData.append("weighbridge_docket[first_tare]", firstTare);
    formData.append("weighbridge_docket[registration_no]", rego);
    formData.append("weighbridge_docket[transport_company]", transportCompany);
    formData.append("weighbridge_docket[driver_name]", driverName);
    formData.append("weighbridge_docket[comment]", remarks);
    formData.append(
      "weighbridge_docket[signature_image][data]",
      signatureString
    );
    // PATCH /api/v1/export_weighbridge_dockets/{id}
    const endpoint = `api/v1/export_weighbridge_dockets/${id}.json`;

    try {
      const res = await axios({
        method: "PUT",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        unsetLoading();
        setAlert("Server error", "danger");
      } else {
        dispatch({ type: SET_NEW_WB_TO_PRINT, payload: res.data.docket });
        await callback();
        setAlert("Export WB updated", "success");
      }
    } catch (err) {
      unsetLoading();
      setAlert("Server error", "danger");
      console.log(err);
    }
  };

  // get pending weighbridge dockets
  const getPendingExportWeighbridgeDockets = async () => {
    setLoading();
    const endpoint = `api/v1/export_weighbridge_dockets/pending.json`;

    try {
      const res = await axios({
        method: "GET",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      if (res.status === 200) {
        dispatch({
          type: GET_PENDING_EXPORT_WEIGHBRIDGE_DOCKETS,
          payload: res.data.dockets,
        });
      } else {
        unsetLoading();
        setAlert("Server error", "danger");
      }
    } catch (err) {
      setAlert("Server error, please try again", "danger");
      console.log(err);
    }
  };

  // set current weighbridge docket
  const setCurrentWeighbridgeDocket = weighbridgeDocket => {
    dispatch({ type: SET_WEIGHBRIDGE_DOCKET, payload: weighbridgeDocket });
  };

  // show loading spinner
  const setLoading = () => dispatch({ type: SET_LOADING });

  // // unset loading spinner
  const unsetLoading = () => dispatch({ type: UNSET_LOADING });

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        currentOrder: state.currentOrder,
        currentContainer: state.currentContainer,
        containers: state.containers,
        loadedProducts: state.loadedProducts,
        preLoadedProducts: state.preLoadedProducts,
        loading: state.loading,
        readyContainers: state.readyContainers,
        newWBData: state.newWBData,
        pendingExportWBDockets: state.pendingExportWBDockets,
        currentWeighbridgeDocket: state.currentWeighbridgeDocket,
        getOrders,
        getLocalOrders,
        setCurrentOrder,
        createContainer,
        getContainers,
        setCurrentContainer,
        loadProduct,
        getLoadedProducts,
        unloadProduct,
        sealContainer,
        completeOrder,
        updateLocalOrders,
        createExportWeighbridgeDocket,
        updateExportWeighbridgeDocket,
        getPendingExportWeighbridgeDockets,
        setCurrentWeighbridgeDocket,
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersState;

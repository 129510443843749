import React, { useContext, useReducer } from "react";
import ProfileReducer from "./profileReducer";
import ProfileContext from "./profileContext";
import axios from "axios";
import setAuthToken from "../../utils/setAuthToken";
import AlertContext from "../alert/alertContext";

import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types";

const ProfileState = props => {
  const alertContext = useContext(AlertContext);
  const { setAlert } = alertContext;
  const initialState = {
    user: "",
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(ProfileReducer, initialState);

  // load user
  const loadUser = async () => {
    const endpoint = `api/v1/users/auth`;

    const config = {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };

    if (localStorage.token) {
      setAuthToken(localStorage.token);

      try {
        const res = await axios({
          method: "post",
          url: process.env.REACT_APP_DEV_API_URL + endpoint,
          config,
        });
        // console.log(res);
        if (res.data.logined) {
          dispatch({ type: USER_LOADED });
        } else {
          dispatch({ type: AUTH_ERROR });
        }
      } catch (err) {
        console.log(err);
        dispatch({ type: AUTH_ERROR });
      }
    }
  };

  // login user
  const login = async formData => {
    const { username, password, rememberMe } = formData;

    const endpoint = "api/v1/sessions";

    const config = {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios({
        method: "post",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        data: {
          account: username,
          password,
        },
        config,
      });
      // console.log(res)
      if (res.data.error) {
        dispatch({ type: AUTH_ERROR, payload: res.data.error });
        // setAlert(res.data.error + ' Please check...', 'danger');
      } else if (res.data.success === true) {
        dispatch({ type: LOGIN_SUCCESS, payload: res.data });
        if (rememberMe) {
          localStorage.setItem("rememberMe", true);
          localStorage.setItem("username", username);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("rememberMe", true);
          localStorage.removeItem("username", username);
          localStorage.removeItem("password", password);
        }
        loadUser();
      }
    } catch (err) {
      dispatch({ type: LOGIN_FAIL, payload: err });
    }
  };

  // update password
  const updatePassword = async (
    oldPassword,
    newPassword,
    newPasswordRepeat,
    props
  ) => {
    var formData = new FormData();

    formData.append("user[origin_password]", oldPassword);
    formData.append("user[password]", newPassword);
    formData.append("user[password_confirmation]", newPasswordRepeat);

    const endpoint = "api/v1/users/update_password.json";

    const config = {
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
    try {
      const res = await axios({
        method: "post",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        data: formData,
        config,
      });
      if (!res.data.success) {
        const errors = res.data.field_errors;
        setAlert(errors[0].message, "danger");
        // errors.forEach(error => console.log(error.message));
      } else {
        setAlert("Password changed!", "success");
        props.history.goBack();
      }
    } catch (err) {
      console.log(err);
    }
  };
  // logout
  const logout = () => dispatch({ type: LOGOUT });
  // clear errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <ProfileContext.Provider
      value={{
        user: state.user,
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        loadUser,
        login,
        logout,
        clearErrors,
        updatePassword,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};

export default ProfileState;

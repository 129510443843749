import React from "react";
import "./ToggleSwitch.css";

const ToggleSwitch = props => {
  return (
    <label className="switch">
      <input
        type="checkbox"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <span className="slider rounded" />
    </label>
  );
};

export default ToggleSwitch;

import React, { useContext, useEffect, useState } from "react";
import "./Camera.css";
import Modal from "./Modal";
import GalleryPhoto from "./GalleryPhoto";
import ImagesContext from "../context/images/imagesContext";

const CameraTest = props => {
  const imagesContext = useContext(ImagesContext);
  const {
    currentImages,
    setCurrentImages,
    resizeImageOnAttach,
    setModalImage,
  } = imagesContext; //for images upload, an array of files
  const { proc } = props;

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // convert file array into images array for page display
  const setShowImageArr = fileArr => {
    const imagesArr = [];
    fileArr.forEach(file => {
      const image = {
        name: file.name,
        url: URL.createObjectURL(file),
      };
      imagesArr.push(image);
    });
    return imagesArr;
  };
  const [imageGallery, setImages] = useState(setShowImageArr(currentImages)); //for showing images to be uploaded, an array of images with names and temp local url

  useEffect(() => {
    setImages(() => setShowImageArr(currentImages));
  }, [currentImages]);

  //handle adding images
  const handleImageChange = e => {
    const fileArr = Object.values(e.target.files);
    if (!currentImages.length) {
      fileArr.forEach(async file => {
        resizeImageOnAttach(file).then(image => {
          currentImages.push(image);
          setCurrentImages(currentImages);
          setImages(() => setShowImageArr(fileArr));
        });
      });
    } else {
      fileArr.forEach(async file => {
        const image = {
          name: file.name,
          // localUrl: URL.createObjectURL(file),
        };
        const found = currentImages.some(el => el.name === image.name);
        if (!found) {
          resizeImageOnAttach(file).then(image => {
            currentImages.push(image);
            setCurrentImages(currentImages);
            setImages(() => setShowImageArr(currentImages));
          });
        }
      });
    }
    // console.log(currentImages);
  };

  return (
    <div className="camera">
      <div className="img-gallery">
        {!imageGallery.length
          ? null
          : imageGallery.map(image => (
              <div key={image.name} className="img-gallery-item">
                {/* <Link
                  to={{ pathname: "/photo", image: { image, deleteImage } }}
                > */}
                <img
                  src={image.url}
                  alt={image.name}
                  onClick={() => {
                    setModalImage(image);
                    handleOpen();
                  }}
                />
                {/* </Link> */}
              </div>
            ))}
      </div>
      <div>
        <label>
          <input
            id="camInput"
            className="btn btn-mat"
            style={{ display: "none" }}
            type="file"
            accept="image/*"
            multiple="multiple"
            onChange={handleImageChange}
          />
          <button
            className={`btn btn-${proc} btn-cam`}
            onClick={() => document.getElementById("camInput").click()}
          >
            <i className="fa-solid fa-camera" />
          </button>
        </label>
      </div>
      <Modal
        proc="fs-modal"
        open={open}
        component={<GalleryPhoto isEdit={true} handleClose={handleClose} />}
      />
    </div>
  );
};

export default CameraTest;

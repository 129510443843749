import React, { Fragment, useContext, useState, useEffect } from "react";
import ProfileContext from "../context/profile/profileContext";
import TasksContext from "../context/tasks/tasksContext";
import HeaderMenu from "./HeaderMenu";
import Modal from "./Modal";
import "./Header.css";
import { Link, useHistory } from "react-router-dom";

export const Header = () => {
  const profileContext = useContext(ProfileContext);
  const tasksContext = useContext(TasksContext);

  const history = useHistory();

  const { loadUser, isAuthenticated } = profileContext;
  const { tasks } = tasksContext;

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line
  }, []);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const authLinks = (
    <Fragment>
      {tasks && tasks.length > 0 ? (
        <li
          className="header-menu-item notification"
          onClick={() => history.push("/tasks")}
        >
          <i className="fa-regular fa-bell"></i>
        </li>
      ) : (
        <li className={`header-menu-item`}>
          <i className="fa-regular fa-bell"></i>
        </li>
      )}
      <li className="header-menu-item">
        <i className="fa-solid fa-user" onClick={handleOpen}></i>
      </li>
    </Fragment>
  );

  return (
    <nav>
      <Link to="/">
        <div className="nav-logo">
          <h4> ACT Belt ERP</h4>
        </div>
      </Link>
      <ul>{isAuthenticated ? authLinks : ""}</ul>
      <Modal
        open={open}
        proc="fs-modal"
        onClose={handleClose}
        component={<HeaderMenu />}
      />
    </nav>
  );
};

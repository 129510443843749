import React, { useContext, useReducer } from "react";
import axios from "axios";
import MemoContext from "./memoContext";
import MemoReducer from "./memoReducer";
import AlertContext from "../alert/alertContext";
import { SET_MEMO, CLEAR_MEMO } from "../types";

const MemoState = props => {
  const alertContext = useContext(AlertContext);

  const { setAlert } = alertContext;

  const initialState = {
    content: "",
    memoType: "",
    memoId: "",
    isCamera: false,
    loadingMemo: false,
  };

  const [state, dispatch] = useReducer(MemoReducer, initialState);

  // set memo
  const setMemo = memo => dispatch({ type: SET_MEMO, payload: memo });

  // submit memo to server
  const createMemo = async (id, type, content, images, callback) => {
    var formData = new FormData();

    if (images.length) {
      var i = images.length;

      for (var x = 0; x < i; x++) {
        formData.append("memo[images][]", images[x]);
      }
    }

    formData.append("memo[memoable_id]", id);
    formData.append("memo[memoable_type]", type);
    formData.append("memo[content]", content);

    const endpoint = `api/v1/memos.json`;

    try {
      const res = await axios({
        method: "POST",
        url: process.env.REACT_APP_DEV_API_URL + endpoint,
        headers: {
          "Content-Type": "multipart/form-data",
          "Access-Control-Allow-Origin": "*",
        },
        data: formData,
      });

      if (!res.data.success) {
        console.log(res);
        setAlert("Server error", "danger");
      } else {
        console.log(res);
        callback();
        setAlert("Memo created", "success");
        // setCurrentMaterial(res.material);
        dispatch({ type: CLEAR_MEMO });
      }
    } catch (err) {
      setAlert("Server error", "danger");
      console.log(err);
    }
  };
  // clear memo

  return (
    <MemoContext.Provider
      value={{
        content: state.content,
        memoType: state.memoType,
        memoId: state.memoId,
        createMemo,
        setMemo,
      }}
    >
      {props.children}
    </MemoContext.Provider>
  );
};

export default MemoState;

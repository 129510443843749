import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../component/Button';

const SupplierBookInComplete = () => {
  const closeWindow = () => {
    window.open("about:blank", "_self");
    window.close();
  }

  const { bookInName } = useParams();
  return (
    <>
      <div className="container">
        <div className="bookin">
          <div className="spreader">
            <Button text={bookInName} className="btn btn-mat" />
            <div>
              <p>Material book-in successful!</p>
              <p>Thank you and have a nice day!</p>
            </div>
            <div>
              <Button text="Close" onClick={closeWindow} className='btn btn-mat' />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SupplierBookInComplete;

import React, { useContext } from "react";
import Button from "../component/Button";
import { Link } from "react-router-dom";
import ProductionContext from "../context/production/productionContext";
import OrdersContext from "../context/orders/ordersContext";
import MaterialContext from "../context/material/materialContext";

const OrderMaterial = props => {
  const productionContext = useContext(ProductionContext);
  const ordersContext = useContext(OrdersContext);
  const materialContext = useContext(MaterialContext);

  const { loadedProducts, unloadProduct } = ordersContext;
  const { stockProducts, setCurrentProduct } = productionContext;
  const { currentMaterial } = materialContext;

  return (
    <>
      <div className="container">
        <div className="orders">
          <div>
            <Button text={currentMaterial.name} className="btn btn-order" />

            <div className="order-stock-products">
              {stockProducts.map(product => {
                if (loadedProducts.some(e => e.id === product.id)) {
                  return (
                    <div key={product.id}>
                      <Button
                        icon={<i className="fa-solid fa-circle-check"></i>}
                        text={product.name}
                        className="btn btn-order"
                        onClick={() => {
                          unloadProduct(product);
                        }}
                      />
                    </div>
                  );
                } else {
                  return (
                    <div key={product.id}>
                      <Button
                        text={product.name + " " + product.grade}
                        className="btn btn-order"
                        onClick={() => {
                          setCurrentProduct(product);
                          props.history.push("/orders/product");
                        }}
                      />
                    </div>
                  );
                }
              })}
            </div>
          </div>
          <div>
            <Link to="/orders/addproducts">
              <Button text="Back" className="btn btn-order" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderMaterial;

import React, { useContext, useState, useEffect } from "react";
import OrdersContext from "../context/orders/ordersContext";
import Button from "../component/Button";
import Modal from "../component/Modal";
import Confirmation from "../component/Confirmation";

const OrderContainer = props => {
  const ordersContext = useContext(OrdersContext);

  const { loadedProducts, unloadProduct, getLoadedProducts } = ordersContext;

  const currentOrder = JSON.parse(localStorage.getItem("currentOrder"));
  const currentContainer = JSON.parse(localStorage.getItem("currentContainer"));

  const containerLoadedSubmit = () => {
    props.history.push("/orders/container/seal");
  };

  // get loadedProducts list
  useEffect(() => {
    getLoadedProducts(currentOrder.order_no, currentContainer.id);
    // eslint-disable-next-line
  }, []);

  //calculate loaded weight
  let totalWeight = 0;
  if (loadedProducts.length > 0) {
    loadedProducts.forEach(product => {
      totalWeight = parseFloat(totalWeight) + parseFloat(product.weight);
    });
  }

  // handle modal open/close - remove product
  const [{ open, product }, setOpen] = useState({
    open: false,
    product: { name: "", id: "" },
  });

  const handleOpen = product => setOpen({ open: true, product: product });
  const handleClose = () =>
    setOpen({ open: false, product: { name: "", id: "" } });

  // handle modal open/close - check before seal
  const [checkOpen, setCheckOpen] = useState(false);

  const handleCheckOpen = () => setCheckOpen(true);
  const handleCheckClose = () => setCheckOpen(false);

  return (
    <div className="container">
      <div className="orders">
        <div>
          <Button
            text={currentContainer.container_no}
            className="btn btn-order"
          />
          <div className="loaded-products">
            {loadedProducts.length > 0 &&
              loadedProducts.map(product => (
                <div key={product.id}>
                  <Button
                    text={product.name}
                    className="btn btn-order"
                    icon={<i className="fa-solid fa-circle-check"></i>}
                    onClick={() => {
                      handleOpen(product);
                    }}
                  />
                </div>
              ))}
          </div>
          <hr />
          <div>Current total weight: {totalWeight} tn</div>
          <div>
            <Button
              text="Add products"
              className="btn btn-order"
              onClick={() => props.history.push("/orders/addproducts")}
            />
          </div>
        </div>
        <div>
          {loadedProducts.length > 0 && (
            <div>
              <Button
                text="Finish Loading"
                className="btn btn-order"
                onClick={handleCheckOpen}
              />
            </div>
          )}
          <Button
            text="Back"
            className="btn btn-order"
            onClick={() => {
              props.history.push("/orders/details");
            }}
          />
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        component={
          <Confirmation
            msg={`${product.name} will be removed from container, Do you wish to proceed?`}
            onConfirm={() =>
              unloadProduct(currentOrder.order_no, currentContainer.id, product)
            }
            onCancel={() => {
              handleClose();
            }}
          />
        }
      />
      <Modal
        open={checkOpen}
        onClose={handleCheckClose}
        component={
          <Confirmation
            msg={`Please check if there is any wooden parts before sealing. Is it OK to proceed?`}
            onConfirm={() => containerLoadedSubmit()}
            onCancel={() => {
              handleCheckClose();
            }}
          />
        }
      />
    </div>
  );
};

export default OrderContainer;
